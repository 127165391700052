import { API_ROUTES } from "../config/routes.config"
import { HttpHelper } from "../helpers/http.helper"

export const getExtendedTrackpacks = async (pathParams = {}, queryParams = {}) => {
    const pathString = pathParams && Object.keys(pathParams).length > 0 ? `/${Object.values(pathParams).join("/")}` : ""
    const queryString = queryParams ? `?${new URLSearchParams({ ...queryParams }).toString()}` : ""
    const url = `${API_ROUTES.GET_ALL_TRACKPACKS_DROPDOWN_LIST}${pathString}${queryString}`

    try {
        const result = await HttpHelper.sendRequest(url, {}, "GET")

        const validatedResponse = await HttpHelper.validateResponse(result)

        const sortByCreatedAt = (data: any) => {
            const { trackpacks } = data
            return trackpacks?.sort(
                // @ts-ignore
                (a: any, b: any) => new Date(b.metadata.created_at) - new Date(a.metadata.created_at)
            )
        }

        const sortedData = sortByCreatedAt(validatedResponse)

        return { trackpacks: sortedData }
    } catch (error) {
        console.error("Failed to fetch dropdown list:", error)
        throw new Error("Error fetching dropdown list")
    }
}
