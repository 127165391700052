export enum ListingAnalyzerAction {
    GET_REPORT_LIST_DATA = "GET_REPORT_LIST_DATA",
    SET_REPORT_LIST_DATA = "SET_REPORT_LIST_DATA",
    SET_REPORT_DATA = "SET_REPORT_DATA",
    SET_REPORT_DATA_EMPTY = "SET_REPORT_DATA_EMPTY",
    GET_REPORT_DATA_BY_ID = "GET_REPORT_DATA_BY_ID",
    SET_REPORT_DATA_BY_ID = "SET_REPORT_DATA_BY_ID",
    CREATE_REPORT = "CREATE_REPORT",
    GET_REPORT_SUMMARY = "GET_REPORT_SUMMARY",
    SET_REPORT_SUMMARY = "SET_REPORT_SUMMARY",
    SET_STRUCTURED_RESPONSE_DATA = "SET_STRUCTURED_RESPONSE_DATA",
    SUBMIT_REPORT_QUESTION_RESPONSE = "SUBMIT_REPORT_QUESTION_RESPONSE",
}

export const ListingAnalyzerActionCreator = {
    getReportsList: (
        pathParams?: { [key: string]: string },
        queryParams?: { [key: string]: string },
        onSuccess?: (data?: any) => void,
        onError?: () => void
    ) => {
        return {
            type: ListingAnalyzerAction.GET_REPORT_LIST_DATA,
            payload: { pathParams, queryParams },
            onSuccess,
            onError,
        }
    },
    setReportsList: (data: any) => ({
        type: ListingAnalyzerAction.SET_REPORT_LIST_DATA,
        payload: data,
    }),
    setReportData: (payload: any) => {
        return {
            type: ListingAnalyzerAction.SET_REPORT_DATA,
            payload,
        }
    },
    removeReportData: () => {
        return {
            type: ListingAnalyzerAction.SET_REPORT_DATA_EMPTY,
        }
    },
    getReportDataByID: (
        reportId: number,
        pathParams?: { [key: string]: any },
        queryParams?: { [key: string]: any },
        onSuccess?: (data?: any) => void,
        onError?: () => void
    ) => {
        return {
            type: ListingAnalyzerAction.GET_REPORT_DATA_BY_ID,
            payload: { reportId, pathParams, queryParams },
            onSuccess,
            onError,
        }
    },
    setReportDataByID: (data: any) => ({
        type: ListingAnalyzerAction.SET_REPORT_DATA_BY_ID,
        payload: data,
    }),
    //  create report

    createReport: (
        payload: Partial<any>,
        onSuccess?: (data?: any) => void,
        onError?: (data: { message: string }) => void
    ): ISagaAction => {
        return {
            type: ListingAnalyzerAction.CREATE_REPORT,
            payload,
            onSuccess,
            onError,
        }
    },

    setStructuredResponseData: (data: any) => ({
        type: ListingAnalyzerAction.SET_STRUCTURED_RESPONSE_DATA,
        payload: data,
    }),

    // Get report Summary

    getReportSummary: (
        reportId: number,
        pathParams?: { [key: string]: any },
        queryParams?: { [key: string]: any },
        onSuccess?: (data?: any) => void,
        onError?: () => void
    ) => {
        return {
            type: ListingAnalyzerAction.GET_REPORT_SUMMARY,
            payload: { reportId, pathParams, queryParams },
            onSuccess,
            onError,
        }
    },
    setReportSummaryData: (data: any) => ({
        type: ListingAnalyzerAction.SET_REPORT_SUMMARY,
        payload: data,
    }),

    submitQuestionResponse: (
        payload: { score: number; reason: string; suggestion: string; reportID: number; reportQuestionID: number },
        onSuccess?: (data?: any) => void,
        onError?: (data: { message: string }) => void
    ): ISagaAction => {
        return {
            type: ListingAnalyzerAction.SUBMIT_REPORT_QUESTION_RESPONSE,
            payload,
            onSuccess,
            onError,
        }
    },
}
