import { handleAmazonProductRedirect, UtilHelper } from "../../helpers/util.helper"
import { PrimaryText } from "../elements/primaryText.element"

import { EyeSvg } from "../../assets/svgs/eye.svg"
import { EyeOffSvg } from "../../assets/svgs/eyeOff.svg"

import { NewExternalLinkIcon } from "../../assets/svgs/newLinkIcon.svg"
import { EmptyStar, FilledStar, PartialStar } from "../../assets/svgs/rating-icon"
import { WarningIcon } from "../../assets/svgs/warningIcon.svg"

export interface Root {
    warnings: string
}

export const ProductCard = (props: IProductCard) => {
    const priceString = props.price!.toString()
    const [integerPart, decimalPart] = priceString.split(".")

    const ownerInitials = props?.metadata?.user_first_name
        ? props?.metadata?.user_first_name.charAt(0)?.toUpperCase()
        : ""
    const getStars = () => {
        const stars = []
        const maxStars = 5
        const fullStars = Math.floor(props.rating!)
        const partialStarPercentage = Math.round((props.rating! - fullStars) * 100)

        for (let i = 0; i < fullStars; i++) {
            stars.push(<FilledStar key={i} />)
        }

        if (partialStarPercentage > 0 && stars.length < maxStars) {
            stars.push(<PartialStar key={fullStars} fillPercentage={partialStarPercentage} />)
        }

        while (stars.length < maxStars) {
            stars.push(<EmptyStar key={stars.length} />)
        }

        return stars
    }

    const checkProductSelected = () => {
        return props.selectedProduct && props.selectedProduct.some((e) => e?.actual_asin === props.id)
    }

    const handleProductRedirect = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation()
        if (checkProductSelected()) {
            handleAmazonProductRedirect(props.amazon_tld, props.id)
        }
    }

    // Previous implementation for ProductStatusIcon

    // const ProductStatusIcon = ({ warnings }: { warnings?: WarningType[] }) => {
    //     const isSuccess = warnings && warnings.includes("success")

    //     let isWarning = false
    //     if (!isSuccess) {
    //         isWarning = !!warnings && warnings.length > 0
    //     }

    //     return (
    //         <div
    //             data-tooltip-id={
    //                 isSuccess
    //                     ? "tracking-error-card-element"
    //                     : isWarning
    //                     ? "tracking-off-element"
    //                     : "tracking-success-element"
    //             }
    //             data-tooltip-content={JSON.stringify({
    //                 warnings,
    //             })}
    //         >
    //             {isSuccess && <RedErrorSvg />}
    //             {/* {isSuccess ? <RedErrorSvg /> : isWarning ? <YellowAlertSvg /> : <GreenCheckSvg />} */}
    //         </div>
    //     )
    // }

    const ProductStatusIcon = (warnings: Root) => {
        return (
            <>
                {warnings.warnings !== null && (
                    <>
                        <div
                            data-tooltip-id={"status-warning-tooltip"}
                            data-tooltip-content={JSON.stringify({
                                trackPackId: props.metadata?.id,
                                warnings: warnings.warnings,
                            })}
                        >
                            <div className=" cursor-pointer">
                                <WarningIcon />
                            </div>
                        </div>
                    </>
                )}
            </>
        )
    }

    return (
        <>
            <div
                data-tooltip-content="Hello world!"
                className={`relative w-[280px]  flex bg-white rounded-[10px] px-[8px] py-[8px] -outline-offset-1`}
                style={{ outline: `1px solid ${props.color.default}` }}
            >
                <div className={` w-full flex  sm:md:h-full cursor-pointer ${!checkProductSelected() ? "" : ""}`}>
                    <img
                        src={props.src}
                        alt={props.altText}
                        loading="lazy"
                        className={`min-w-[68px] max-w-[68px] h-[68px] rounded mr-[10px] object-contain ${
                            !checkProductSelected() ? "opacity-20" : ""
                        }`}
                        style={{ border: `1px solid ${props.color.default}` }}
                    />
                    <div className="w-full flex flex-col justify-between h-[68px]">
                        <div className={`flex justify-between`}>
                            <div className={`flex gap-[4px] ${!checkProductSelected() ? "opacity-20" : ""}`}>
                                <div
                                    className={`bg-transparent relative cursor-pointer border rounded-[3px] p-[4px] pr-[3px] flex gap-[3px] justify-center items-center h-[18px]`}
                                    style={{ borderColor: `${props.color?.default}` }}
                                >
                                    <p
                                        onClick={handleProductRedirect}
                                        className={`text-[${props.color?.default}] text-[11px] tracking-[0.7px] font-[500] leading-[8px] uppercase`}
                                    >
                                        {props.id}
                                    </p>
                                    <div onClick={handleProductRedirect} className="cursor-pointer">
                                        <NewExternalLinkIcon color={props.color?.default} className="" />
                                        {/* <LinkExternalSvg color={props.color?.default} className="mb-[0.5px]" /> */}
                                    </div>
                                </div>
                                {props.ownProduct && ownerInitials !== "" && (
                                    <div className="flex justify-center items-center w-[16px] rounded-[4px] bg-modal-btn-gradient h-[16px]">
                                        <PrimaryText
                                            weight="book"
                                            size="xs-small"
                                            className=" text-[#FFF] leading-[15px] uppercase"
                                        >
                                            {ownerInitials}
                                        </PrimaryText>
                                    </div>
                                )}

                                {/* {props.reviewer && (
                                    <img
                                        src={props.reviewer}
                                        alt={props.reviewer}
                                        loading="lazy"
                                        className={"w-[12px] h-[12px] rounded-[3px]"}
                                    />
                                )} */}
                            </div>
                            <div className={`flex ${props.currentIssue ? "gap-[4px]" : ""}`}>
                                <ProductStatusIcon warnings={props.currentIssue} />
                                {!checkProductSelected() ? (
                                    <div className="w-[16px] h-[16px]">
                                        <EyeOffSvg />
                                    </div>
                                ) : (
                                    <div className="w-[16px] h-[16px]">
                                        <EyeSvg />
                                    </div>
                                )}
                            </div>
                        </div>
                        {props?.title && (
                            <PrimaryText
                                weight={"medium"}
                                className={`text-[13px]  text-ellipsis overflow-hidden whitespace-nowrap line-clamp-1 min-h-[13px] mt-[18px] text-gray-700 leading-[12px] ${
                                    !checkProductSelected() ? "opacity-20" : ""
                                } `}
                            >
                                {UtilHelper.truncateTitle(props?.title, 25)}
                            </PrimaryText>
                        )}

                        <div className={`mt-[6px] mb-[4px] h-[12px]  ${!checkProductSelected() ? "opacity-20" : ""}`}>
                            <div className={"flex gap-[10px] items-center justify-between h-[12px]"}>
                                <PrimaryText className="flex items-start gap-[1px]">
                                    <sup className={"text-gray-700 text-[7px]  font-[400] leading-[13px] "}>
                                        {props.currency}
                                    </sup>
                                    <PrimaryText className={"text-gray-700 text-[13px] font-[500] leading-[13px]"}>
                                        {integerPart}
                                    </PrimaryText>
                                    {decimalPart && (
                                        <>
                                            <sup className={"text-gray-700 text-[7px] font-[400]  leading-[13px]"}>
                                                {decimalPart}
                                            </sup>
                                        </>
                                    )}
                                </PrimaryText>
                                <div className={"flex gap-[6px] items-start leading-normal"}>
                                    <PrimaryText
                                        size={"xs"}
                                        weight={"book"}
                                        className={"flex gap-[6px] items-start text-slate-700"}
                                    >
                                        <p className="text-[13px] leading-[13px]  font-normal tracking-[-0.26px] ">
                                            {props.rating}
                                        </p>
                                        <span className={"inline-flex items-center"}>
                                            {getStars().map((star, index) => (
                                                <span key={index}>{star}</span>
                                            ))}
                                        </span>
                                        <PrimaryText
                                            weight={"book"}
                                            className={"text-[13px] leading-[13px]  font-normal tracking-[-0.26px]"}
                                        >
                                            {props.ratingsCount?.toLocaleString()}
                                        </PrimaryText>
                                    </PrimaryText>
                                </div>
                                <div className={"flex items-center"}>
                                    <img src={props.reviewer} alt={""} className={"max-h-[16px] max-w-[16px]"} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
