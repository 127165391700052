const CarouselIcon = ({ strokeColor,width,height }: { strokeColor?: string,width?: string,height?: string }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width ?? 14} height={height ?? 14} viewBox="0 0 14 14" fill="none">
            <path
                d="M1.44141 6.99933L6.7982 9.67773C6.87108 9.71417 6.90752 9.73239 6.94574 9.73956C6.97959 9.74591 7.01433 9.74591 7.04818 9.73956C7.0864 9.73239 7.12284 9.71417 7.19572 9.67773L12.5525 6.99933M1.44141 9.77711L6.7982 12.4555C6.87108 12.4919 6.90752 12.5102 6.94574 12.5173C6.97959 12.5237 7.01433 12.5237 7.04818 12.5173C7.0864 12.5102 7.12284 12.4919 7.19572 12.4555L12.5525 9.77711M1.44141 4.22155L6.7982 1.54316C6.87108 1.50672 6.90752 1.4885 6.94574 1.48133C6.97959 1.47497 7.01433 1.47497 7.04818 1.48133C7.0864 1.4885 7.12284 1.50672 7.19572 1.54316L12.5525 4.22155L7.19572 6.89995C7.12284 6.93639 7.0864 6.95461 7.04818 6.96178C7.01433 6.96813 6.97959 6.96813 6.94574 6.96178C6.90752 6.95461 6.87108 6.93639 6.7982 6.89995L1.44141 4.22155Z"
                stroke={strokeColor || "#667085"}
                stroke-width="0.8"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    )
}

export default CarouselIcon
