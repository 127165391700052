import dayjs from "dayjs"
import { Image } from "image-js"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { ImageUnavailable } from "../../../assets/svgs/imageUnavailable.svg"
import { useProductHistory } from "../../../hooks/productHistory.hook"
import { RunTimeStateSelector } from "../../../selectors/RunTimeStateSelector"
import ArrowModalActions from "../../common/arrowModalActions.component"
import InnerModalHeader from "../../common/innerModalHeader.component"
import { PrimaryText } from "../../elements/primaryText.element"
import { ChartsWrapper } from "./chartsWrapper.component"

const colorList = [
    { name: "CFF9FE", activeColor: "06AED4", color: [6, 174, 212] },
    { name: "FEE4E2", activeColor: "F04438", color: [240, 68, 56] },
    { name: "FEF0C7", activeColor: "F79009", color: [247, 144, 9] },
    { name: "DCFAE6", activeColor: "17B26A", color: [23, 178, 106] },
    { name: "FCE7F6", activeColor: "EE46BC", color: [238, 70, 188] },
]

const MainImgModal = (data: any) => {
    const { t: tCommon } = useTranslation("tooltip", {
        keyPrefix: "commonLabels",
    })
    const { active, history, setActive, total } = useProductHistory(data?.data, "main_image")
    const { modal } = useSelector(RunTimeStateSelector)

    const [image, setImage] = useState(null)
    const [coloredImageSrc, setColoredImageSrc] = useState<string | null>(null)
    const [showOnlyUnread, setShowOnlyUnread] = useState(false)

    const firstImageURL = history?.present?.value
    const pastImageURL = history?.past?.value
    const defaultColor = colorList.find((color) => color.activeColor === "06AED4")?.color || [6, 174, 212]
    const [selectedColor, setSelectedColor] = useState<number[] | null>(null)

    useEffect(() => {
        const loadImage = async () => {
            if (firstImageURL) {
                try {
                    const img = await Image.load(firstImageURL)
                    // @ts-ignore
                    setImage(img)
                    setColoredImageSrc(firstImageURL)
                } catch (error) {
                    console.error("Error loading image:", error)
                }
            }
        }
        loadImage()
    }, [firstImageURL])
    useEffect(() => {
        if (selectedColor) {
            recolorImageDifference()
        }
    }, [selectedColor])

    const recolorImageDifference = async () => {
        if (!image || !selectedColor || !pastImageURL || !showOnlyUnread) return

        try {
            const presentImage = await Image.load(firstImageURL)
            const pastImage = await Image.load(pastImageURL)

            const [width, height] = [presentImage.width, presentImage.height]
            const resizedPastImage = pastImage.resize({ width, height })

            const data = new Uint8Array(width * height * 4).fill(0)
            const diffImage = new Image(width, height, data, {
                // @ts-ignore
                kind: "RGBA",
            })

            const [highlightR, highlightG, highlightB] = selectedColor
            const alpha = 0.5

            let hasDifference = false

            for (let x = 0; x < width; x++) {
                for (let y = 0; y < height; y++) {
                    const [r1, g1, b1] = resizedPastImage.getPixelXY(x, y)
                    const [r2, g2, b2] = presentImage.getPixelXY(x, y)

                    const diffThreshold = 5
                    const isDifferent =
                        Math.abs(r1 - r2) > diffThreshold ||
                        Math.abs(g1 - g2) > diffThreshold ||
                        Math.abs(b1 - b2) > diffThreshold

                    if (isDifferent) {
                        hasDifference = true
                        const blendedR = alpha * highlightR + (1 - alpha) * r2
                        const blendedG = alpha * highlightG + (1 - alpha) * g2
                        const blendedB = alpha * highlightB + (1 - alpha) * b2

                        diffImage.setPixelXY(x, y, [blendedR, blendedG, blendedB, 255])
                    } else {
                        diffImage.setPixelXY(x, y, [0, 0, 0, 0])
                    }
                }
            }

            if (hasDifference) {
                const blob = await diffImage.toBlob()
                const diffImageURL = URL.createObjectURL(blob)
                setColoredImageSrc(diffImageURL)
            } else {
                setColoredImageSrc(firstImageURL)
            }
        } catch (error) {
            console.error("Error applying color difference:", error)
        }
    }

    const handleToggle = () => {
        setShowOnlyUnread((prev) => {
            if (!prev) {
                // @ts-ignore
                setSelectedColor(defaultColor)
                return true
            } else {
                setSelectedColor(null)
                setColoredImageSrc(firstImageURL)
                return false
            }
        })
    }

    const handleColorSelection = (color: { name: string; activeColor: string; color: number[] }) => {
        setSelectedColor(color.color)
    }

    return (
        <ChartsWrapper
            className={`p-[48px] absolute top-[50%] left-[50%] -translate-y-2/4 -translate-x-2/4 bg-white rounded-[24px] m-auto ${
                history?.past && history?.past?.value && history?.present?.value ? "w-[900px]" : "w-[492px]"
            } shadow-modal-shadow border-[1px] border-solid border-[#E7EBEF] transition-all`}
        >
            <InnerModalHeader color={modal?.color} type="main_image" />
            <PrimaryText className="text-[#344054] text-[24px] font-[500] leading-[32px] mb-[4px] ml-[6px]">
                Main Image
            </PrimaryText>
            <div className={`ml-[8px] flex items-center gap-[10px]`}>
                <ArrowModalActions total={total} active={active} setActive={setActive} />
                <PrimaryText className="text-[12px] uppercase leading-[12px] text-[#667085] font-[400]">
                    {tCommon("timestamp", {
                        timestamp: dayjs(history.present?.timestamp).format("MMM-DD-YYYY @ HH:mm [GMT]"),
                    })}
                </PrimaryText>
            </div>

            <div className="flex gap-[12px] mt-[40px]">
                {history.past && (
                    <div className="bg-[#F2F4F7] rounded-[16px] p-4">
                        <div className="w-[32px] py-[5.5px] px-[6px] bg-[#F04438] text-white rounded-[6px] mb-[16px] text-[10px] leading-[9px] font-[500] flex items-center justify-center">
                            {tCommon("was")}
                        </div>
                        <div className="w-[364px] h-[364px] overflow-hidden border border-gray-200 rounded-[8px]">
                            {history.past.value === " " ? (
                                <div className="object-contain min-w-[384px] max-w-[384px] h-[384px] rounded-[4px] border border-gray-400 select-none flex items-center justify-center bg-gray-900">
                                    <ImageUnavailable />
                                </div>
                            ) : (
                                <img
                                    className="w-full h-full object-contain bg-white"
                                    src={history.past.value}
                                    alt=""
                                />
                            )}
                        </div>
                    </div>
                )}

                {history.present && (
                    <div className="bg-[#F2F4F7] p-4 rounded-[16px]">
                        <div className="w-[32px] py-[5.5px] px-[6px] text-white bg-[#47CD89] rounded-[6px] mb-[16px] text-[10px] leading-[9px] font-[500] flex items-center justify-center">
                            {tCommon("now")}
                        </div>
                        <div className="w-[364px] h-[364px] overflow-hidden border border-gray-200 rounded-[8px] relative">
                            {history.present.value === " " ? (
                                <div className="object-contain min-w-[384px] max-w-[384px] h-[384px] rounded-[4px] border border-gray-400 select-none flex items-center justify-center bg-gray-900">
                                    <ImageUnavailable />
                                </div>
                            ) : (
                                <img
                                    className="w-full h-full rounded object-contain cursor-pointer bg-white absolute inset-0 "
                                    src={coloredImageSrc || history.present.value}
                                    alt="mainIMage"
                                />
                            )}
                        </div>
                        {data?.data?.isFirst === false && (
                            <div className="flex items-center gap-[24px] mt-[16px] justify-center">
                                <div className="flex items-center gap-[4px]">
                                    <div className="whitespace-pre-line outline-none my-[1px] text-xs leading-[1.125rem] font-medium text-gray-500">
                                        Show changes
                                    </div>
                                    <label className="switches">
                                        <input
                                            id="toggle-input"
                                            type="checkbox"
                                            checked={showOnlyUnread}
                                            onChange={handleToggle}
                                        />
                                        <span className="sliders rounds"></span>
                                    </label>
                                </div>

                                <div className="flex items-center gap-[4px]">
                                    <label className="text-xs leading-[1.125rem] my-[1px] font-medium text-gray-500">
                                        Highlight
                                    </label>
                                    <div className="flex gap-[2px] bg-white rounded-[12px] p-[2px] border border-gray-200">
                                        {colorList.map(({ name, color, activeColor }) => (
                                            <button
                                                key={name}
                                                style={{
                                                    backgroundColor: `#${
                                                        selectedColor && selectedColor === color ? activeColor : name
                                                    }`,
                                                }}
                                                className={`w-[16px] h-[16px] rounded-full `}
                                                onClick={() => handleColorSelection({ name, activeColor, color })}
                                            >
                                                <span className="sr-only">{name}</span>
                                            </button>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </ChartsWrapper>
    )
}

export default MainImgModal
