import { API_ROUTES } from "../config/routes.config"
import { HttpHelper } from "../helpers/http.helper"
import { UtilHelper } from "../helpers/util.helper"

export class ListingAnalyzerService {
    static async getReportsList(pathParams?: { [key: string]: string }, queryParams?: { [key: string]: string }) {
        const pathString =
            pathParams && Object.keys(pathParams).length > 0 ? `/${Object.values(pathParams).join("/")}` : ""
        const queryString = queryParams ? `?${new URLSearchParams({ ...queryParams }).toString()}` : ""
        const url = `${API_ROUTES.GET_REPORTS_LIST}${pathString}${queryString}`
        const result = await HttpHelper.sendRequest(url, {}, "GET")

        return HttpHelper.validateResponse<IAlertSettingState>(result)
    }

    static async getReportDataById(
        reportId: number,
        pathParams?: { [key: string]: string },
        queryParams?: { [key: string]: string }
    ) {
        const pathString =
            pathParams && Object.keys(pathParams).length > 0 ? `/${Object.values(pathParams).join("/")}` : ""
        const queryString = queryParams ? `?${new URLSearchParams({ ...queryParams }).toString()}` : ""
        const url = `${API_ROUTES.GET_REPORT_DATA_BY_ID}/${reportId}${pathString}${queryString}`
        const result = await HttpHelper.sendRequest(url, {}, "GET")

        return HttpHelper.validateResponse<TrackpackDatum>(result)
    }

    // CREATE REPORT
    static async createReport(payload: Partial<any>): Promise<Response> {
        const result = await HttpHelper.sendRequest(
            API_ROUTES.CREATE_REPORT,

            { ...payload },

            "POST"
        )

        if (result && !UtilHelper.isEmptyObject(result) && !result.error) {
            return await UtilHelper.getJsonResponse(result)
        }
        // @ts-ignore
        return undefined
    }
    // report summary
    static async getReportSummaryData(
        reportId: number,
        pathParams?: { [key: string]: string },
        queryParams?: { [key: string]: string }
    ) {
        const pathString =
            pathParams && Object.keys(pathParams).length > 0 ? `/${Object.values(pathParams).join("/")}` : ""
        const queryString = queryParams ? `?${new URLSearchParams({ ...queryParams }).toString()}` : ""
        const url = `${API_ROUTES.GET_REPORT_DATA_BY_ID}/${reportId}/summary${pathString}${queryString}`
        const result = await HttpHelper.sendRequest(url, {}, "GET")

        return HttpHelper.validateResponse<TrackpackDatum>(result)
    }

    static async submitReponse(payload: any): Promise<any> {
        const { reportID, reportQuestionID, score, suggestion, reason } = payload

        const url = `${API_ROUTES.SUBMIT_REPORT_QUESTION_RESPONSE}/${reportID}/questions/${reportQuestionID}/response`
        const dataPayload = {
            score: score,
            suggestion: suggestion,
            reason: reason,
        }
        const result = await HttpHelper.sendRequest(url, dataPayload, "POST")

        if (result && !UtilHelper.isEmptyObject(result) && !result.error) {
            return await UtilHelper.getJsonResponse(result)
        }

        return undefined
    }
}
