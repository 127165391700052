const StepNavigator = ({ currentStep, totalSteps, strokeColor, previousStep }: any) => {
    return (
        <div className="flex items-center justify-center space-y-4">
            <div className="flex items-center justify-center space-x-[4px]">
                {Array.from({ length: totalSteps }).map((_, index) => {
                    // Determine the color for each step
                    let bgColor = "bg-gray-200" // Default for steps after the current step
                    if (index < currentStep - 1) {
                        bgColor = "bg-[#088AB2]" // Completed steps
                    } else if (index === currentStep - 1) {
                        bgColor = "bg-[#67E3F9]" // Current step
                    }

                    return <div key={index} className={`h-[4px] w-[34px] rounded-[2px] ${bgColor}`}></div>
                })}
            </div>
        </div>
    )
}

export default StepNavigator
