import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { ListingAnalyzerActionCreator } from "../../../../actions/listingAnalyzer.action"
import { PaginationLeftArrow } from "../../../../assets/svgs/paginationLeftArrow.svg"
import { PaginationRightArrow } from "../../../../assets/svgs/paginationRightArrow.svg"
import { CrossNewIcon } from "../../../../assets/svgs/xIcon.svg"
import { img_url } from "../../../../config/dashboard.config"
import { ListingAnalyzerStateSelector } from "../../../../selectors/listingAnalyzerState.selector"
import StepNavigator from "../../../common/stepsNavigator.component"
import { PrimaryText } from "../../../elements/primaryText.element"

const ListingReportModalStepThreeView: React.FC<ReportModalFlowProps> = ({
    asinHeaderText,
    icon,
    handleCloseDialog,
    handleNext,
    buttonText,
    isPrevButton,
    handlePrev,
    totalSteps,
    currentStep,
    previousStep,
    asinHeaderDescription,
    currentStepData,
    updateStepData,
}) => {
    const { control, handleSubmit } = useForm()
    const dispatch = useDispatch()
    const { reportPayload } = useSelector(ListingAnalyzerStateSelector)

    // Get filtered trackings
    const filteredTrackings = reportPayload?.selectedTrackpack?.trackings?.filter(
        (tracking: any) => tracking.asin !== reportPayload.target_asin.asin
    )

    const filteredImages = filteredTrackings?.map((tracking: any) => ({
        asin: tracking.asin,
        main_image_filename: tracking.latest_data.main_image_filename,
    }))

    // State for selected ASINs
    const [selectedAsins, setSelectedAsins] = useState<string[]>([]) // Initialize as an empty array

    useEffect(() => {
        updateStepData(selectedAsins)
    }, [selectedAsins])

    // Handle form submission
    const onSubmit = () => {
        if (selectedAsins?.length >= 2) {
            let competitor: string[] = []
            competitor = selectedAsins || []
            const payload = {
                trackpack_id: reportPayload?.selectedTrackpack?.metadata?.id,
                type: "MAIN_IMAGE_ANALYSIS",
                target: reportPayload?.target_asin?.asin,
                competitors: competitor?.map((val: string) => val?.toUpperCase()) ?? [],
            }

            dispatch(
                ListingAnalyzerActionCreator.createReport(
                    { ...payload },
                    () => {
                        dispatch(ListingAnalyzerActionCreator.getReportsList())
                        handleNext && handleNext()
                    },
                    (res: { message: string }) => {}
                )
            )
        }
    }

    const handleCardClick = (asin: string) => {
        if (!Array.isArray(selectedAsins)) return

        if (selectedAsins.includes(asin)) {
            setSelectedAsins(selectedAsins.filter((item) => item !== asin))
        } else if (selectedAsins.length < 2) {
            setSelectedAsins([...selectedAsins, asin])
        }
    }

    return (
        <>
            <div className="flex items-center justify-between">
                <div className="rounded-[12px] p-[10px]">{icon}</div>
                <div className="cursor-pointer">
                    <CrossNewIcon onClick={handleCloseDialog} />
                </div>
            </div>

            <form
                onSubmit={handleSubmit(onSubmit)}
                className="mt-[36px]"
                style={
                    {
                        // maxHeight: "300px",  Define the max height for the form
                    }
                }
            >
                <div
                    className="bg-gray-100 border border-gray-200 p-[16px] rounded-[16px] relative"
                    style={{
                        width: "calc(100% - 10px)", // Adjust to fit the design
                    }}
                >
                    <div
                        className="grid grid-cols-5 gap-[12px] overflow-y-auto pr-[20px] semi-automated" // Padding for scrollbar
                        style={{
                            maxHeight: "300px", // Limit the scrollable height
                            marginRight: "-38px", // Push scrollbar outside the gray panel
                            paddingRight: "38px",
                        }}
                    >
                        {filteredImages?.map((product: any, index: number) => (
                            <div
                                key={index}
                                className={`relative rounded-[8px] w-[144px] h-[144px] overflow-hidden border ${
                                    selectedAsins.includes(product.asin) ? "border-[#088AB2]" : "border-gray-200"
                                } bg-white flex items-center justify-center cursor-pointer`}
                                onClick={() => handleCardClick(product.asin)}
                            >
                                <img
                                    alt={"product"}
                                    className="w-full h-full object-contain"
                                    src={`${img_url}${product.main_image_filename}`}
                                />
                                <div
                                    className={`absolute bottom-2 left-2 w-[20px] h-[20px] rounded-full  flex items-center justify-center ${
                                        selectedAsins.includes(product.asin)
                                            ? "bg-[#088AB2] "
                                            : "bg-white border border-gray-200"
                                    }`}
                                >
                                    {selectedAsins.includes(product.asin) && (
                                        <svg
                                            width="14"
                                            height="14"
                                            viewBox="0 0 14 14"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M11.6668 3.5L5.25016 9.91667L2.3335 7"
                                                stroke="white"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <PrimaryText
                    size="sm-medium"
                    weight="light"
                    className="text-gray-700 mt-[36px] ml-[6px] max-w-[730px] w-full mx-auto text-center"
                >
                    Here are all of the ASINs from your Trackpack called{" "}
                    <b className="font-medium">{reportPayload?.selectedTrackpack?.metadata?.name}</b> (excluding your
                    target ASIN). Click on the <b className="font-medium">2 Main Images</b> that are the most
                    eye-catching.
                </PrimaryText>
                <div className="flex justify-center gap-4 items-center mt-[36px]">
                    <div className="flex items-center">
                        <PrimaryText
                            onClick={handlePrev}
                            className={"bg-[#ECFDFF] border border-[#67E3F9] rounded-[6px] p-[5px] cursor-pointer"}
                        >
                            <PaginationLeftArrow stroke={"#06AED4"} />
                        </PrimaryText>
                    </div>

                    <button type="submit" className="flex items-center">
                        <PrimaryText
                            className={` ${
                                selectedAsins.length >= 2 ? "bg-[#A5F0FC]" : "bg-gray-100"
                            }  rounded-[6px] p-[5px] cursor-pointer`}
                        >
                            <PaginationRightArrow stroke={selectedAsins.length >= 2 ? "#0E7090" : "#D0D5DD"} />
                        </PrimaryText>
                    </button>
                </div>{" "}
            </form>

            <div className="mt-10">
                <StepNavigator
                    currentStep={currentStep}
                    previousStep={previousStep}
                    totalSteps={totalSteps}
                    strokeColor="#667085"
                />
            </div>
        </>
    )
}

export default ListingReportModalStepThreeView
