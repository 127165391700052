import { useDispatch } from "react-redux"
import { RuntimeActionCreators } from "../../../actions/runTime.action"
import { ReactComponent as Logo } from "../../../assets/svgs/dashboard-logo.svg"
import useGetExtendedTrackpacks from "../../../hooks/reactQueryHooks.hook"
import { PrimaryText } from "../../elements/primaryText.element"

const ListingAnalyzerSekeleton = () => {
    const dispatch = useDispatch()
    const { refetch } = useGetExtendedTrackpacks({}, {}, false)

    const handleLisitingReportFlow = () => {
        refetch()
        dispatch(
            RuntimeActionCreators.openListingAnalyzerReportModal({
                open: true,
                stepNumber: "one",
            })
        )
    }

    return (
        <div
            style={{
                height: "calc(100vh - 262px)",
            }}
            className="bg-white p-[16px] mt-[40px] rounded-[10px] border border-gray-200"
        >
            <div className="border-2 border-[#088AB2] rounded-[4px]">
                <div className="max-w-[660px] w-full mx-auto pt-[61px] pb-[85px] px-[24px] flex flex-col items-center justify-center gap-[16px]">
                    <Logo />
                    <PrimaryText weight="medium" size="sm-medium" className="text-center text-gray-600">
                        Welcome to my Listing Analyzer, a powerful tool designed to help you understand how strong each
                        part of an ASIN’s listing is compared to the competition.
                    </PrimaryText>
                    <PrimaryText
                        weight="medium"
                        size="sm-medium"
                        className="text-center text-gray-600 max-w-[520px] w-full"
                    >
                        I’ll help you analyze all aspects of any ASIN’s listing so you can determine if it can be
                        improved and how.
                    </PrimaryText>
                    <PrimaryText weight="light" size="small" className="text-gray-600">
                        <span
                            onClick={handleLisitingReportFlow}
                            className="text-[#088AB2] text-[14px] font-medium leading-[20px] cursor-pointer"
                        >
                            Click Here
                        </span>
                        &nbsp;to select a Trackpack and start analyzing.
                    </PrimaryText>
                </div>
            </div>
        </div>
    )
}

export default ListingAnalyzerSekeleton
