import dayjs from "dayjs"
import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import { NotificationsActionsCreator } from "../../actions/notifications.action"
import { ProductsActionCreator } from "../../actions/products.action"
import { GreenArrow } from "../../assets/svgs/greenArrow.svg"
import { ReactComponent as NoImage } from "../../assets/svgs/no-image.svg"
import { NotificationChevronDownIcon } from "../../assets/svgs/NotificationChevronDownIcon"
import { NotificationHalfRedIcon } from "../../assets/svgs/notificationHalfRedDot.svg"
import { NotificationHalfRedIconHover } from "../../assets/svgs/notificationHalfRedIconHover.svg"
import { NotificationRedIcon } from "../../assets/svgs/notificationRedDot.svg"
import { NotificationRedIconHover } from "../../assets/svgs/notificationRedIconHover.svg"
import { NotificationWhiteIcon } from "../../assets/svgs/notificationWhiteIcon.svg"
import { NotificationWhiteIconHover } from "../../assets/svgs/notificationWhiteIconHover.svg"
import { img_url } from "../../config/dashboard.config"
import { ROUTES_CONFIG } from "../../config/routes.config"
import { determineNotificationType, renderNotificationIcon } from "../../helpers/util.helper"
import { AccountStateSelector } from "../../selectors/accountState.selector"
import { PrimaryText } from "../elements/primaryText.element"
import NotificationAsinCardByTrackpack from "./notificationAsinCardByTrackpack.component"

export const NotificationCardByTrackpack = (props: INotificationCard) => {
    const [menuIconClicked, setMenuIconClicked] = useState<number | null>(null)
    const [hoveredIcon, setHoveredIcon] = useState(false)
    const [selectedNotificationId, setSelectedNotificationId] = useState<number | null>(null)
    const notificationCardRefs = useRef<{ [key: number]: HTMLDivElement | null }>({})
    const { userActionData } = useSelector(AccountStateSelector)

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation()
    const { pathname } = location

    const { trackpackInfo, ...notificationsByDate } = props?.product

    useEffect(() => {
        const storedNotificationId = sessionStorage.getItem("selectedNotificationId")
        if (storedNotificationId) {
            setSelectedNotificationId(Number(storedNotificationId))
        }

        const storedMenuState = sessionStorage.getItem("menuIconClicked")
        if (storedMenuState) {
            setMenuIconClicked(Number(storedMenuState))
        }
    }, [])

    const iconType = renderNotificationIcon(props?.product)

    const renderIcon = () => {
        switch (iconType) {
            case "red":
                return (
                    <div onMouseOver={() => setHoveredIcon(true)} onMouseLeave={() => setHoveredIcon(false)}>
                        {hoveredIcon ? <NotificationRedIconHover /> : <NotificationRedIcon />}
                        <div
                            style={{
                                boxShadow: "0px 2px 4px -2px #1018280F, 0px 4px 8px -2px #1018281A",
                            }}
                            className="opacity-0 min-w-[68px] absolute left-[-34px] top-[50%] translate-y-[-50%] z-50 transform translate-x-[-50%] group-hover:opacity-100 transition-all ease-in-out duration-300 bg-gray-400 text-[8px] leading-[11px] text-center font-[500] text-white py-[4px] px-[6px] rounded-[4px]"
                        >
                            Mark as read
                        </div>
                    </div>
                )
            case "gray":
                return (
                    <div onMouseOver={() => setHoveredIcon(true)} onMouseLeave={() => setHoveredIcon(false)}>
                        {hoveredIcon ? <NotificationWhiteIconHover /> : <NotificationWhiteIcon />}
                        <div
                            style={{
                                boxShadow: "0px 2px 4px -2px #1018280F, 0px 4px 8px -2px #1018281A",
                            }}
                            className="opacity-0 min-w-[68px] absolute left-[-34px] top-[50%] translate-y-[-50%] z-50 transform translate-x-[-50%] group-hover:opacity-100 transition-all ease-in-out duration-300 bg-gray-400 text-[8px] leading-[11px] text-center font-[500] text-white py-[4px] px-[6px] rounded-[4px]"
                        >
                            Mark as unread
                        </div>
                    </div>
                )

            case "half":
                return (
                    <div onMouseOver={() => setHoveredIcon(true)} onMouseLeave={() => setHoveredIcon(false)}>
                        {hoveredIcon ? <NotificationHalfRedIconHover /> : <NotificationHalfRedIcon />}
                        <div
                            style={{
                                boxShadow: "0px 2px 4px -2px #1018280F, 0px 4px 8px -2px #1018281A",
                            }}
                            className="opacity-0 min-w-[68px] absolute left-[-34px] top-[50%] translate-y-[-50%] z-50 transform translate-x-[-50%] group-hover:opacity-100 transition-all ease-in-out duration-300 bg-gray-400 text-[8px] leading-[11px] text-center font-[500] text-white py-[4px] px-[6px] rounded-[4px]"
                        >
                            Mark as read
                        </div>
                    </div>
                )
            default:
                return null
        }
    }

    const handleTrackpackRedirect = (
        e: React.MouseEvent<HTMLDivElement>,
        id: number,
        asins: any[],
        notificationNumber: number
    ) => {
        e.stopPropagation()

        if (
            props?.product?.trackpackInfo?.status === "ACTIVE" ||
            props?.product?.trackpackInfo?.status === "AWAITING_INITIAL_DATA"
        ) {
            const asinsArray = asins.map((asin: any) => asin.requested_asin)
            dispatch(
                NotificationsActionsCreator.notifiSelection({
                    notifiAsin: asinsArray,
                    selectedTrackpackID: id?.toString(),
                })
            )

            dispatch(ProductsActionCreator.getExtendedTrackingData(String(id), {}, { only_active_trackings: true }))
            dispatch(
                ProductsActionCreator.setSelectedTrackPackValues({
                    selectedTrackpackID: String(id),
                    defualtSelectedAsin: asinsArray,
                })
            )

            if (pathname !== ROUTES_CONFIG.dashboard) {
                navigate(ROUTES_CONFIG.dashboard)
            }
            if (!userActionData) {
                let payload = {
                    notification_ids: asins.flatMap((asin: any) =>
                        Array.isArray(asin.notification_id) ? asin.notification_id : [asin.notification_id]
                    ),
                }
                dispatch(NotificationsActionsCreator.markNotificationAsRead(payload))
            }
        } else if (props?.product?.trackpackInfo?.status === "ARCHIVED") {
            localStorage.setItem("trackpackHubId", id.toString())
            navigate(ROUTES_CONFIG.trackpackCentral)
        }
        setSelectedNotificationId(notificationNumber)
        sessionStorage.setItem("selectedNotificationId", notificationNumber.toString())

        setMenuIconClicked(notificationNumber)
        sessionStorage.setItem("menuIconClicked", notificationNumber.toString())
        props?.onClose!()
    }

    const extractedNotifications = Object.entries(notificationsByDate)
        ?.filter(([key, value]) => key !== "trackpackInfo")
        ?.flatMap(
            ([date, notifications]) => (notifications as any)?.map((notification: any) => ({ ...notification, date }))
        )

    // @ts-ignore
    const sortedNotifications = extractedNotifications?.sort((a, b) => new Date(a.date) - new Date(b.date))

    const startDate = sortedNotifications[0]?.date
    const endDate = sortedNotifications[sortedNotifications.length - 1]?.date

    const notificationType = determineNotificationType(extractedNotifications)

    const handleMenuStateChange = (notificationNumber: number) => {
        const previousNotificationId = selectedNotificationId
        setSelectedNotificationId(notificationNumber)
        sessionStorage.setItem("selectedNotificationId", notificationNumber.toString())
        if (previousNotificationId !== null) {
            sessionStorage.removeItem(`menuIconClicked`)
            setMenuIconClicked(null)
        }
        setMenuIconClicked((prevState) => (!prevState ? notificationNumber : null))
        sessionStorage.setItem("menuIconClicked", notificationNumber.toString())
    }

    const handleChevronClick = (e: React.MouseEvent<HTMLDivElement>, notificationId: number) => {
        e.stopPropagation()
        if (menuIconClicked === notificationId) {
            setMenuIconClicked(null)
            sessionStorage.removeItem("menuIconClicked")
            sessionStorage.removeItem("selectedNotificationId")
        } else {
            setSelectedNotificationId(notificationId)
            sessionStorage.setItem("selectedNotificationId", notificationId?.toString())
            setMenuIconClicked(notificationId)
            sessionStorage.setItem("menuIconClicked", notificationId?.toString())
        }
    }

    const handleMarkAsRead = (e: React.MouseEvent<HTMLDivElement>, notifications: any[]) => {
        e.stopPropagation()
        if (!userActionData) {
            const isAllRead = notifications.every((notification) => notification.read_at !== null)

            let payload = {
                notification_ids: notifications.flatMap((notification: any) =>
                    Array.isArray(notification.notification_id)
                        ? notification.notification_id
                        : [notification.notification_id]
                ),
            }

            if (isAllRead) {
                dispatch(NotificationsActionsCreator.markNotificationAsUnRead({ ...payload, isUnread: true }))
            } else {
                dispatch(NotificationsActionsCreator.markNotificationAsRead(payload))
            }
        }
    }

    return (
        <>
            <div
                className="relative flex gap-[12px] w-full cursor-pointer"
                ref={(el) => (notificationCardRefs.current[props.product.trackpackInfo?.notificationNumber] = el)}
                onClick={(e) => handleMarkAsRead(e, extractedNotifications)}
            >
                {extractedNotifications?.[0]?.main_image_filename ? (
                    <img
                        src={`${img_url}${extractedNotifications?.[0]?.main_image_filename}`}
                        alt="Trackpack"
                        className="h-[44px] max-w-[44px] min-w-[44px] border border-gray-300 rounded-[6px] object-contain bg-white"
                    />
                ) : (
                    <div className="h-[44px] min-w-[44px] border border-gray-300 rounded-[4px] flex items-center justify-center bg-gray-800">
                        <NoImage />
                    </div>
                )}
                <div className="w-full">
                    <div className="flex items-center justify-between">
                        <div className="flex items-center gap-[8px] mt-[2px]">
                            {notificationType === "listing_change" ? (
                                <PrimaryText size="xs-medium" weight="medium" className="leading-[16px] text-gray-600">
                                    Listing Changes
                                </PrimaryText>
                            ) : notificationType === "asin_added" ? (
                                <PrimaryText size="xs-medium" weight="medium" className="leading-[16px] text-gray-600">
                                    ASINs ready
                                </PrimaryText>
                            ) : notificationType === "trackpack_ready" ? (
                                <PrimaryText size="xs-medium" weight="medium" className="leading-[16px] text-gray-600">
                                    Trackpack ready
                                </PrimaryText>
                            ) : (
                                <PrimaryText size="xs-medium" weight="medium" className="leading-[16px] text-gray-600">
                                    ASIN Errors
                                </PrimaryText>
                            )}
                            <div>
                                <PrimaryText
                                    weight="book"
                                    className="text-gray-500 text-[9px] leading-[10px] flex items-center gap-[2px] my-[4px]"
                                >
                                    {dayjs(endDate).format("MMM-DD-YYYY").toUpperCase()}
                                    {dayjs(startDate).isSame(endDate, "day") ? null : (
                                        <>
                                            <GreenArrow />
                                            {dayjs(startDate).format("MMM-DD-YYYY").toUpperCase()}
                                        </>
                                    )}
                                </PrimaryText>
                            </div>
                        </div>
                        <div className="mr-[10px] cursor-pointer relative group">{renderIcon()}</div>
                    </div>

                    <div className="flex items-start w-full justify-between">
                        <PrimaryText size="small" weight="light" className="text-gray-700 mt-[4px] mb-[2px]">
                            <PrimaryText size="small" weight="light" className="text-gray-700">
                                {notificationType === "listing_change" ? (
                                    <>
                                        Your Trackpack&nbsp;
                                        <PrimaryText weight="medium" size="small" className="text-gray-700 inline">
                                            {props?.product?.trackpackInfo?.name}&nbsp;
                                        </PrimaryText>
                                        has listing changes&nbsp;&nbsp;
                                    </>
                                ) : notificationType === "asin_added" ? (
                                    <>
                                        New ASINs added to your Trackpack&nbsp;
                                        <PrimaryText
                                            weight="medium"
                                            size="small"
                                            className="text-gray-700 inline-block"
                                        >
                                            {props?.product?.trackpackInfo?.name}&nbsp;&nbsp;
                                        </PrimaryText>
                                    </>
                                ) : notificationType === "trackpack_ready" ? (
                                    <>
                                        Your Trackpack&nbsp;
                                        <PrimaryText
                                            weight="medium"
                                            size="small"
                                            className="text-gray-700 inline-block"
                                        >
                                            {props?.product?.trackpackInfo?.name}&nbsp;
                                        </PrimaryText>
                                        has been successfully set up&nbsp;&nbsp;
                                    </>
                                ) : (
                                    <>
                                        Your Trackpack&nbsp;
                                        <PrimaryText weight="medium" size="small" className="text-gray-700 inline">
                                            {props?.product?.trackpackInfo?.name}&nbsp;
                                        </PrimaryText>
                                        has ASINs with errors&nbsp;&nbsp;
                                    </>
                                )}
                                <span
                                    className={`text-[#088AB2] font-medium text-sm inline-block cursor-pointer`}
                                    onClick={(e: React.MouseEvent<HTMLDivElement>) =>
                                        handleTrackpackRedirect(
                                            e,
                                            props?.product?.trackpackInfo?.id!,
                                            extractedNotifications,
                                            props?.product?.trackpackInfo?.notificationNumber!
                                        )
                                    }
                                >
                                    View now
                                </span>
                            </PrimaryText>
                        </PrimaryText>

                        <div
                            className="w-[20px] h-[20px] cursor-pointer flex items-center mr-[10px]"
                            onClick={(e) => {
                                handleChevronClick(e, props?.product?.trackpackInfo?.notificationNumber)
                            }}
                        >
                            <NotificationChevronDownIcon
                                className={`cursor-pointer w-[20px] h-[20px] ${
                                    menuIconClicked === props?.product?.trackpackInfo?.notificationNumber
                                        ? "rotate-0"
                                        : "rotate-[-90deg]"
                                }`}
                            />
                        </div>
                    </div>
                </div>
            </div>

            {menuIconClicked === props?.product?.trackpackInfo?.notificationNumber &&
                Object.entries(notificationsByDate).map(([date, notifications]: any) => (
                    <NotificationAsinCardByTrackpack
                        key={date}
                        date={date}
                        notifications={notifications}
                        trackpack={trackpackInfo}
                        handleMenuStateChange={handleMenuStateChange}
                        onClose={props?.onClose}
                    />
                ))}
        </>
    )
}
