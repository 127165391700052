import { fork, put } from "redux-saga/effects"
import { all, call, takeLatest } from "typed-redux-saga"
import { ListingAnalyzerAction, ListingAnalyzerActionCreator } from "../actions/listingAnalyzer.action"
import { RuntimeActionCreators } from "../actions/runTime.action"
import { transformData } from "../helpers/util.helper"
import { ListingAnalyzerService } from "../services/listingAnalyzer.service"

function* reportsList() {
    yield takeLatest(ListingAnalyzerAction.GET_REPORT_LIST_DATA, getReportListHandler)
}

function* getReportListHandler<T extends ISagaAction>(action: T) {
    try {
        yield put(RuntimeActionCreators.startLoading("getReportListHandler", !action.ignorePreloader))
        const { pathParams, queryParams } = action.payload
        const result = yield* call(ListingAnalyzerService.getReportsList, pathParams, queryParams)

        if (result) {
            yield put(ListingAnalyzerActionCreator.setReportsList(result))
        }
    } catch (error) {
    } finally {
        yield put(RuntimeActionCreators.stopLoading("getReportListHandler"))
        action.onFinally && action.onFinally()
    }
}

function* getReportDataByID() {
    yield takeLatest(ListingAnalyzerAction.GET_REPORT_DATA_BY_ID, getReportDataByIdHandler)
}

function* getReportDataByIdHandler<T extends ISagaAction & { payload: { reportId: number } }>(action: T) {
    try {
        yield put(RuntimeActionCreators.startLoading("getReportDataByIdHandler", !action.ignorePreloader))
        const { reportId, pathParams, queryParams } = action.payload
        const result = yield* call(ListingAnalyzerService.getReportDataById, reportId, pathParams, queryParams)

        if (result) {
            action.onSuccess && action.onSuccess(result)
            yield put(ListingAnalyzerActionCreator.setReportDataByID(result))
            const updatedData = transformData(result)
            yield put(ListingAnalyzerActionCreator.setStructuredResponseData(updatedData))
        }
    } catch (error) {
    } finally {
        yield put(RuntimeActionCreators.stopLoading("getReportDataByIdHandler"))
        action.onFinally && action.onFinally()
    }
}

// CREATE REPORT

function* createReport() {
    yield takeLatest(ListingAnalyzerAction.CREATE_REPORT, createReportHandler)
}

function* createReportHandler<T extends ISagaAction & { payload: Partial<any> }>(action: T) {
    try {
        // Dispatch a start loading action
        yield put(RuntimeActionCreators.startLoading("createReportHandler", !action.ignorePreloader))

        const result = yield* call(ListingAnalyzerService.createReport, action.payload)
        if (result) {
            action.onSuccess && action.onSuccess(result)
            const updatedData = transformData(result)
            yield put(ListingAnalyzerActionCreator.setStructuredResponseData(updatedData))
        } else {
            action.onError && action.onError(result)
        }
    } catch (error) {
    } finally {
        yield put(RuntimeActionCreators.stopLoading("createReportHandler"))

        action.onFinally && action.onFinally()
    }
}

// add response

function* submitResponse() {
    yield takeLatest(ListingAnalyzerAction.SUBMIT_REPORT_QUESTION_RESPONSE, submitResponseHandler)
}

function* submitResponseHandler<
    T extends ISagaAction & {
        payload: { score: number; reason: string; suggestion: string; reportID: number; reportQuestionID: number }
        onError?: (error: any) => void
        onFinally?: () => void
    },
>(action: T) {
    try {
        // Dispatch a start loading action
        yield put(RuntimeActionCreators.startLoading("submitResponseHandler", !action.ignorePreloader))

        const result = yield* call(ListingAnalyzerService.submitReponse, action.payload)

        if (result) {
            action.onSuccess && action.onSuccess(result)
        } else {
            action.onError && action.onError(result)
        }
    } catch (error) {
        action.onError && action.onError(error)
    } finally {
        yield put(RuntimeActionCreators.stopLoading("submitResponseHandler"))
        action.onFinally && action.onFinally()
    }
}

// Get Report Summary

function* getReportSummary() {
    yield takeLatest(ListingAnalyzerAction.GET_REPORT_SUMMARY, getReportSummaryHandler)
}

function* getReportSummaryHandler<T extends ISagaAction & { payload: { reportId: number } }>(action: T) {
    try {
        yield put(RuntimeActionCreators.startLoading("getReportSummaryHandler", !action.ignorePreloader))
        const { reportId, pathParams, queryParams } = action.payload
        const result = yield* call(ListingAnalyzerService.getReportSummaryData, reportId, pathParams, queryParams)

        if (result) {
            action.onSuccess && action.onSuccess(result)
            yield put(ListingAnalyzerActionCreator.setReportSummaryData(result))
        }
    } catch (error) {
    } finally {
        yield put(RuntimeActionCreators.stopLoading("getReportSummaryHandler"))
        action.onFinally && action.onFinally()
    }
}

export default function* rootSaga() {
    yield all([
        fork(reportsList),
        fork(getReportDataByID),
        fork(createReport),
        fork(getReportSummary),
        fork(submitResponse),
    ])
}
