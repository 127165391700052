export const PaginationRightArrow = (props: IArrowProps) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M3.33325 8.00016H12.6666M12.6666 8.00016L7.99992 3.3335M12.6666 8.00016L7.99992 12.6668"
                stroke={props?.stroke ?? "#D0D5DD"}
                stroke-width="1.2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    )
}
