import { useQuery } from "@tanstack/react-query"
import { getExtendedTrackpacks } from "../services/getExtendedTrackpack"

const useGetExtendedTrackpacks = (pathParams: any, queryParams: any, enabled?: boolean) => {
    return useQuery({
        queryKey: ["extended_trackpacks", pathParams, queryParams],
        queryFn: () => getExtendedTrackpacks(pathParams, queryParams),
        enabled,
        refetchInterval: 180000,
        refetchOnWindowFocus: true,
    })
}

export default useGetExtendedTrackpacks
