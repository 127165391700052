import { useEffect, useRef, useState } from "react"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { ListingAnalyzerActionCreator } from "../../../../actions/listingAnalyzer.action"
import { PaginationLeftArrow } from "../../../../assets/svgs/paginationLeftArrow.svg"
import { PaginationRightArrow } from "../../../../assets/svgs/paginationRightArrow.svg"
import { CrossNewIcon } from "../../../../assets/svgs/xIcon.svg"
import { img_url } from "../../../../config/dashboard.config"
import { ListingAnalyzerStateSelector } from "../../../../selectors/listingAnalyzerState.selector"
import StepNavigator from "../../../common/stepsNavigator.component"
import { PrimaryText } from "../../../elements/primaryText.element"
import { ListinReportModalProductCards } from "../../../listingReportModalProductCards.component"

const ListingReportModalStepTwoView: React.FC<ReportModalFlowProps> = ({
    asinHeaderText,
    icon,
    handleCloseDialog,
    handleNext,
    buttonText,
    isPrevButton,
    handlePrev,
    totalSteps,
    currentStep,
    asinHeaderDescription,
    previousStep,
    updateStepData,
    currentStepData,
}) => {
    const { control, handleSubmit, watch, setValue } = useForm()
    const dispatch = useDispatch()
    const total_asins = watch("total_asins")
    const [selectedCard, setSelectedCard] = useState(currentStepData)

    const { reportPayload } = useSelector(ListingAnalyzerStateSelector)

    const [hasScroll, setHasScroll] = useState(false)
    const scrollableDivRef = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
        if (scrollableDivRef.current) {
            setHasScroll(scrollableDivRef.current.scrollHeight > scrollableDivRef.current.clientHeight)
        }
    }, [])

    const onSubmit = () => {
        if (selectedCard) {
            dispatch(ListingAnalyzerActionCreator.setReportData({ target_asin: selectedCard }))
            handleNext && handleNext()
        }
    }

    const handleCardClick = (asin: string) => {
        setSelectedCard((prev: any) => ({ ...prev, asin }))
    }

    useEffect(() => {
        updateStepData(selectedCard)
    }, [selectedCard])

    return (
        <>
            <div className="flex items-center justify-between">
                <div className=" rounded-[12px] p-[10px]">{icon}</div>
                <div className="cursor-pointer">
                    <CrossNewIcon onClick={handleCloseDialog} />
                </div>
            </div>
            <PrimaryText className="text-gray-700 text-[24px] font-medium mt-[24px] ml-[6px]">
                {asinHeaderText}
            </PrimaryText>

            <PrimaryText size="sm-medium" weight="light" className="text-gray-700 mt-[4px] ml-[6px]">
                {asinHeaderDescription}
            </PrimaryText>

            <form onSubmit={handleSubmit(onSubmit)} className=" mt-[36px]">
                <>
                    <div
                        ref={scrollableDivRef}
                        className={`grid grid-cols-3 gap-[12px] overflow-y-auto semi-automated`}
                        style={{
                            paddingRight: hasScroll ? "12px" : "0",
                            maxHeight: "278px",
                        }}
                    >
                        {reportPayload?.selectedTrackpack?.trackings?.map(
                            (trackingStatus: any, index: number | null | undefined) => {
                                const amazon_tld = trackingStatus?.metadata?.amazon_tld
                                const asin = trackingStatus?.asin
                                const isSelected = asin === selectedCard?.asin

                                return (
                                    <div
                                        key={index}
                                        className={`last:pb-[12px] cursor-pointer rounded-lg`}
                                        onClick={() => handleCardClick(asin)}
                                    >
                                        <ListinReportModalProductCards
                                            amazon_tld={amazon_tld}
                                            id={trackingStatus?.asin}
                                            src={
                                                trackingStatus.latest_data.main_image_filename &&
                                                `${img_url}${trackingStatus.latest_data.main_image_filename}`
                                            }
                                            altText={"Asin dress"}
                                            title={trackingStatus.latest_data.title ?? ""}
                                            price={trackingStatus.latest_data.price ?? 0}
                                            rating={trackingStatus.latest_data.rating ?? 0}
                                            ratingsCount={trackingStatus.latest_data.ratings_total ?? 0}
                                            color={trackingStatus?.color}
                                            currency={trackingStatus.latest_data.currency ?? "USD"}
                                            ownProduct={trackingStatus.own_product}
                                            metadata={trackingStatus.metadata}
                                            currentIssue={trackingStatus.currentIssue}
                                            isSelected={isSelected}
                                        />
                                    </div>
                                )
                            }
                        )}
                    </div>

                    <div className="flex justify-center gap-4  items-center mt-[36px]">
                        <div className="flex items-center">
                            <PrimaryText
                                onClick={handlePrev}
                                className={
                                    " bg-[#ECFDFF] -outline-offset-1 outline-1 outline-[#67E3F9] rounded-[6px] p-[5px] cursor-pointer "
                                }
                            >
                                <PaginationLeftArrow stroke={"#06AED4"} />
                            </PrimaryText>
                        </div>

                        <button type="submit" className="flex items-center">
                            <PrimaryText
                                className={` ${
                                    selectedCard?.asin ? "bg-[#A5F0FC]" : "bg-gray-100"
                                }  rounded-[6px] p-[5px] cursor-pointer`}
                            >
                                <PaginationRightArrow stroke={selectedCard?.asin ? "#0E7090" : "#D0D5DD"} />
                            </PrimaryText>
                        </button>
                    </div>
                </>
            </form>
            <div className="mt-10">
                <StepNavigator
                    currentStep={currentStep}
                    previousStep={previousStep}
                    totalSteps={totalSteps}
                    strokeColor="#667085"
                />
            </div>
        </>
    )
}

export default ListingReportModalStepTwoView
