import dayjs from "dayjs"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import { NotificationsActionsCreator } from "../../actions/notifications.action"
import { ProductsActionCreator } from "../../actions/products.action"
import { LinkExternalSvg } from "../../assets/svgs/link-external.svg"
import { ReactComponent as NoImage } from "../../assets/svgs/no-image.svg"
import { NotificationGreyIcon } from "../../assets/svgs/notificationGreyIcon.svg"
import { NotificationGreyIconHover } from "../../assets/svgs/notificationGreyIconHover.svg"
import { NotificationRedIcon } from "../../assets/svgs/notificationRedDot.svg"
import { NotificationRedIconHover } from "../../assets/svgs/notificationRedIconHover.svg"
import { SuccessIcon } from "../../assets/svgs/successIcon.svg"
import { AsinChangeTooltip } from "../../assets/svgs/tooltips/asin-change.svg"
import { CarouselIconTooltip } from "../../assets/svgs/tooltips/carousel.svg"
import { DescriptionIconTooltip } from "../../assets/svgs/tooltips/description.svg"
import { MainImageTooltip } from "../../assets/svgs/tooltips/main-image.svg"
import { PriceIconTooltip } from "../../assets/svgs/tooltips/price-icon.svg"
import { SellerInfoIconTooltip } from "../../assets/svgs/tooltips/seller-info.svg"
import { StockTooltipIcon } from "../../assets/svgs/tooltips/stock.svg"
import { TitleIconTooltip } from "../../assets/svgs/tooltips/title.svg"
import { BulletsIconTooltip } from "../../assets/svgs/tooltips/tooltip-bullets.svg"
import { VideoIconTooltip } from "../../assets/svgs/tooltips/video.svg"
import { WarningIcon } from "../../assets/svgs/warningIcon.svg"
import { AsinIssuesList, img_url } from "../../config/dashboard.config"
import { ROUTES_CONFIG } from "../../config/routes.config"
import {
    checkArchivedStatusAndNavigate,
    countOccurrences,
    determineNotificationType,
    getTooltipMessage,
    handleAmazonProductRedirect,
} from "../../helpers/util.helper"
import { AccountStateSelector } from "../../selectors/accountState.selector"
import { ProductStateSelector } from "../../selectors/product.selector"
import { PrimaryText } from "../elements/primaryText.element"

const NotificationAsinCardByTrackpack = ({
    date,
    notifications,
    trackpack,
    handleMenuStateChange,
    onClose,
}: {
    date: string
    notifications: any[]
    trackpack: any
    handleMenuStateChange?: (notificationNumber: number) => void
    onClose?: () => void
}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const { pathname } = location
    const [hoveredIcon, setHoveredIcon] = useState<number | null>(null)
    const { userActionData } = useSelector(AccountStateSelector)
    const { selectedDropdownList } = useSelector(ProductStateSelector)

    const handleProductRedirect = (asin: string) => {
        handleAmazonProductRedirect(trackpack?.amazon_tld!, asin!)
    }

    const handleNotificationRead = (read_at: string, id: number, requested_asin: string, pd_timestamp = "") => {
        if (!userActionData && read_at === null) {
            let payload = {
                notification_ids: Array.isArray(id) ? id : [id],
            }

            dispatch(NotificationsActionsCreator.markNotificationAsRead({ ...payload }))
        }

        const isArchived = checkArchivedStatusAndNavigate(
            selectedDropdownList?.trackpacks,
            trackpack?.id!,
            requested_asin
        )
        if (isArchived) {
            return navigate(ROUTES_CONFIG.trackpackCentral)
        } else {
            dispatch(
                NotificationsActionsCreator.notifiSelection({
                    notifiAsin: [requested_asin],
                    selectedTrackpackID: trackpack?.id!?.toString(),
                    timeStamp: pd_timestamp,
                })
            )
            sessionStorage.setItem("timeStamp", pd_timestamp)

            dispatch(
                ProductsActionCreator.getExtendedTrackingData(
                    String(trackpack?.id),
                    {},
                    { only_active_trackings: true }
                )
            )
            dispatch(
                ProductsActionCreator.setSelectedTrackPackValues({
                    selectedTrackpackID: String(trackpack?.id),
                    defualtSelectedAsin: requested_asin,
                })
            )

            if (pathname !== ROUTES_CONFIG.dashboard) {
                navigate(ROUTES_CONFIG.dashboard)
            }
            handleMenuStateChange && handleMenuStateChange(trackpack?.notificationNumber!)
        }

        onClose && onClose()
    }

    const renderIcon = (key: string, count: number | null) => {
        switch (key) {
            case "actual_asin_id":
                return (
                    <>
                        <div className="flex items-center bg-gray-200 rounded-[3px]">
                            <AsinChangeTooltip width={"14"} height={"14"} stroke={"#667085"} className={`m-[1px] `} />
                            {count && (
                                <PrimaryText
                                    weight="book"
                                    className="flex items-center justify-center m-[2px] ml-0 text-[7.5px] leading-[13px] w-[12px] h-[12px] p-[2px] bg-white rounded-[2px] text-gray-500"
                                >
                                    {count}
                                </PrimaryText>
                            )}
                        </div>
                    </>
                )
            case "seller_id":
                return (
                    <>
                        <div className="flex items-center bg-gray-200 rounded-[3px]">
                            <SellerInfoIconTooltip
                                width={"14"}
                                height={"14"}
                                stroke={"#667085"}
                                className={`m-[1px]`}
                            />
                            {count && (
                                <PrimaryText
                                    weight="book"
                                    className="flex items-center justify-center m-[2px] ml-0 text-[7.5px] leading-[13px] w-[12px] h-[12px] p-[2px] bg-white rounded-[2px] text-gray-500"
                                >
                                    {count}
                                </PrimaryText>
                            )}
                        </div>
                    </>
                )
            case "availability_id":
                return (
                    <>
                        <div className="flex items-center bg-gray-200 rounded-[3px]">
                            <StockTooltipIcon width={"14"} height={"14"} stroke={"#667085"} className={`m-[1px]`} />
                            {count && (
                                <PrimaryText
                                    weight="book"
                                    className="flex items-center justify-center m-[2px] ml-0  text-[7.5px] leading-[13px] w-[12px] h-[12px] p-[2px] bg-white rounded-[2px] text-gray-500"
                                >
                                    {count}
                                </PrimaryText>
                            )}
                        </div>
                    </>
                )
            case "price":
                return (
                    <>
                        <div className="flex items-center bg-gray-200 rounded-[3px]">
                            <PriceIconTooltip width={"14"} height={"14"} stroke={"#667085"} className={"m-[1px]"} />
                            {count && (
                                <PrimaryText
                                    weight="book"
                                    className="flex items-center justify-center m-[2px] ml-0 text-[7.5px] leading-[13px] w-[12px] h-[12px] p-[2px] bg-white rounded-[2px] text-gray-500"
                                >
                                    {count}
                                </PrimaryText>
                            )}
                        </div>
                    </>
                )
            case "title_id":
                return (
                    <>
                        <div className="flex items-center bg-gray-200 rounded-[3px]">
                            <TitleIconTooltip width={"14"} height={"14"} stroke={"#667085"} className={`m-[1px] `} />
                            {count && (
                                <PrimaryText
                                    weight="book"
                                    className="flex items-center justify-center m-[2px] ml-0 text-[7.5px] leading-[13px] w-[12px] h-[12px] p-[2px] bg-white rounded-[2px] text-gray-500"
                                >
                                    {count}
                                </PrimaryText>
                            )}
                        </div>
                    </>
                )

            case "bullets_id":
                return (
                    <>
                        <div className="flex items-center bg-gray-200 rounded-[3px]">
                            <BulletsIconTooltip width={"14"} height={"14"} stroke={"#667085"} className={` m-[1px]`} />
                            {count && (
                                <PrimaryText
                                    weight="book"
                                    className="flex items-center justify-center m-[2px] ml-0  text-[7.5px] leading-[13px] w-[12px] h-[12px] p-[2px] bg-white rounded-[2px] text-gray-500"
                                >
                                    {count}
                                </PrimaryText>
                            )}
                        </div>
                    </>
                )
            case "description_id":
                return (
                    <>
                        <div className="flex items-center bg-gray-200 rounded-[3px]">
                            <DescriptionIconTooltip
                                width={"14"}
                                height={"14"}
                                stroke={"#667085"}
                                className={`m-[1px] `}
                            />
                            {count && (
                                <PrimaryText
                                    weight="book"
                                    className="flex items-center justify-center m-[2px] ml-0  text-[7.5px] leading-[13px] w-[12px] h-[12px] p-[2px] bg-white rounded-[2px] text-gray-500"
                                >
                                    {count}
                                </PrimaryText>
                            )}
                        </div>
                    </>
                )
            case "main_image_id":
                return (
                    <>
                        <div className="flex items-center bg-gray-200 rounded-[3px]">
                            <MainImageTooltip width={"14"} height={"14"} stroke={"#667085"} className={`m-[1px]`} />
                            {count && (
                                <PrimaryText
                                    weight="book"
                                    className="flex items-center justify-center m-[2px] ml-0  text-[7.5px] leading-[13px] w-[12px] h-[12px] p-[2px] bg-white rounded-[2px] text-gray-500"
                                >
                                    {count}
                                </PrimaryText>
                            )}
                        </div>
                    </>
                )
            case "carousel_images_id":
                return (
                    <>
                        <div className="flex items-center bg-gray-200 rounded-[3px]">
                            <CarouselIconTooltip width={"14"} height={"14"} stroke={"#667085"} className={`m-[1px]`} />
                            {count && (
                                <PrimaryText
                                    weight="book"
                                    className="flex items-center justify-center m-[2px] ml-0  text-[7.5px] leading-[13px] w-[12px] h-[12px] p-[2px] bg-white rounded-[2px] text-gray-500"
                                >
                                    {count}
                                </PrimaryText>
                            )}
                        </div>
                    </>
                )
            case "videos":
                return (
                    <>
                        <div className="flex items-center bg-gray-200 rounded-[3px]">
                            <VideoIconTooltip width={"14"} height={"14"} stroke={"#667085"} className={`mt-[1px]`} />
                            {count && (
                                <PrimaryText
                                    weight="book"
                                    className="flex items-center justify-center m-[2px] ml-0 text-[7.5px] leading-[13px] w-[12px] h-[12px] p-[2px] bg-white rounded-[2px] text-gray-500"
                                >
                                    {count}
                                </PrimaryText>
                            )}
                        </div>
                    </>
                )

            default:
                return null
        }
    }

    const iconOrder = [
        "actual_asin_id",
        "seller_id",
        "availability_id",
        "price",
        "title_id",
        "bullets_id",
        "description_id",
        "main_image_id",
        "carousel_images_id",
        "videos",
    ]

    const renderIconsInOrder = (changes: string[], changeCounts: Record<string, number>) => {
        const sortedChanges = changes.sort((a, b) => iconOrder.indexOf(a) - iconOrder.indexOf(b))

        return sortedChanges.map((change, index) => (
            <span key={index}>{renderIcon(change, changeCounts[change] > 1 ? changeCounts[change] : null)}</span>
        ))
    }

    const handleAsinNotificationRead = (e: React.MouseEvent<HTMLDivElement>, notifications: any) => {
        e.stopPropagation()
        function flattenArray(arr: any) {
            return arr.flat()
        }
        if (!userActionData) {
            let arr = flattenArray(notifications.notification_id)
            let payload = {
                notification_ids: Array.isArray(notifications.notification_id) ? arr : [notifications.notification_id],
            }

            if (notifications.read_at !== null) {
                dispatch(NotificationsActionsCreator.markNotificationAsUnRead({ ...payload, isUnread: true }))
            } else {
                dispatch(NotificationsActionsCreator.markNotificationAsRead(payload))
            }
        }
    }

    const notificationType = determineNotificationType(notifications)
    return (
        <>
            <div className="ml-[50px] mt-[16px] min-w-[73px] max-w-[71px] flex items-center justify-center px-[8px] py-[6px] pt-[7px] bg-white rounded-tl-[3px] rounded-tr-[3px]">
                <PrimaryText weight="book" className="text-[9px] leading-[8px] text-gray-500">
                    {dayjs(date).format("MMM-DD-YYYY").toUpperCase()}
                </PrimaryText>
            </div>
            <div className="border border-white  ml-[50px]" />

            <div>
                {notifications.map((notification) => (
                    <div
                        className="flex gap-[12px] mt-[8px] bg-white p-[8px] pr-[10px] rounded-[3px] ml-[50px]"
                        key={notification.notification_id}
                    >
                        {notification?.main_image_filename !== null ? (
                            <img
                                src={`${img_url}${notification?.main_image_filename}`}
                                alt="product images"
                                className="min-w-[44px] max-w-[44px] h-[44px] border border-gray-300 overflow-hidden object-contain rounded-[6px] bg-white"
                            />
                        ) : (
                            <div className="min-w-[44px] max-w-[44px] h-[44px] flex-1 flex items-center justify-center border border-gray-300 overflow-hidden rounded-[4px] object-contain bg-gray-800">
                                <NoImage />
                            </div>
                        )}

                        {notificationType === "listing_change" ? (
                            <div
                                className={`w-full cursor-pointer`}
                                onClick={() => {
                                    handleNotificationRead(
                                        notification?.read_at,
                                        notification.notification_id,
                                        notification.requested_asin,
                                        notification?.pd_timestamp
                                    )
                                }}
                            >
                                <div className=" flex items-center justify-between w-full">
                                    <div className="flex items-center gap-x-[4px] mt-[2px]">
                                        <SuccessIcon />
                                        <PrimaryText
                                            size="xs-medium"
                                            weight="medium"
                                            className="leading-[12px] flex text-gray-700 my-[2px]"
                                        >
                                            {notification?.changes.length}{" "}
                                            {notification?.changes.length === 1 ? "change" : "changes"} to&nbsp;
                                            <PrimaryText
                                                weight="medium"
                                                size="xs-medium"
                                                className="text-[#088AB2] uppercase inline-flex gap-x-[2px]"
                                                onClick={() => handleProductRedirect(notification.requested_asin)}
                                            >
                                                {notification?.requested_asin}
                                                <LinkExternalSvg color={"#088AB2"} className="mb-[1px]" />
                                            </PrimaryText>
                                        </PrimaryText>
                                    </div>
                                    {notification?.read_at === null ? (
                                        <div
                                            className="relative group"
                                            onMouseOver={() =>
                                                setHoveredIcon(
                                                    notification?.notification_id! && notification?.notification_id
                                                )
                                            }
                                            onMouseLeave={() => setHoveredIcon(null)}
                                            onClick={(e) => handleAsinNotificationRead(e, notification)}
                                        >
                                            {hoveredIcon === notification?.notification_id! &&
                                            notification?.notification_id ? (
                                                <NotificationRedIconHover />
                                            ) : (
                                                <NotificationRedIcon />
                                            )}

                                            <div
                                                style={{
                                                    boxShadow: "0px 2px 4px -2px #1018280F, 0px 4px 8px -2px #1018281A",
                                                }}
                                                className="opacity-0 min-w-[68px] absolute left-[-34px] top-[50%] translate-y-[-50%] z-50 transform translate-x-[-50%] group-hover:opacity-100 transition-all ease-in-out duration-300 bg-gray-400 text-[8px] leading-[11px] text-center font-[500] text-white py-[4px] px-[6px] rounded-[4px]"
                                            >
                                                Mark as read
                                            </div>
                                        </div>
                                    ) : (
                                        <div
                                            className="relative group"
                                            onMouseOver={() =>
                                                setHoveredIcon(
                                                    notification?.notification_id! && notification?.notification_id
                                                )
                                            }
                                            onMouseLeave={() => setHoveredIcon(null)}
                                            onClick={(e) => handleAsinNotificationRead(e, notification)}
                                        >
                                            {hoveredIcon === notification?.notification_id! &&
                                            notification?.notification_id ? (
                                                <NotificationGreyIconHover />
                                            ) : (
                                                <NotificationGreyIcon />
                                            )}

                                            <div
                                                style={{
                                                    boxShadow: "0px 2px 4px -2px #1018280F, 0px 4px 8px -2px #1018281A",
                                                }}
                                                className="opacity-0 min-w-[65px] absolute left-[-34px] top-[50%] px-[6px] translate-y-[-50%] z-50 transform translate-x-[-50%] group-hover:opacity-100 transition-all ease-in-out duration-300 bg-gray-400 text-[8px] leading-[11px] text-center font-[500] text-white py-[4px] rounded-[4px]"
                                            >
                                                Mark as unread
                                            </div>
                                        </div>
                                    )}
                                </div>

                                <div className="flex items-center gap-[2px] mt-[6px] flex-wrap mb-[2px]">
                                    {notification?.changes &&
                                        (() => {
                                            const changeCounts = countOccurrences(notification?.changes)
                                            return renderIconsInOrder(Object.keys(changeCounts), changeCounts)
                                        })()}
                                </div>
                            </div>
                        ) : notificationType === "trackpack_ready" ? (
                            <div
                                className={`w-full cursor-pointer`}
                                onClick={() => {
                                    handleNotificationRead(
                                        notification?.read_at,
                                        notification.notification_id,
                                        notification.requested_asin,
                                        notification?.pd_timestamp
                                    )
                                }}
                            >
                                <div className=" flex items-center justify-between w-full">
                                    <div className="flex items-center gap-x-[4px] mt-[2px]">
                                        <SuccessIcon />
                                        <PrimaryText
                                            size="xs-medium"
                                            weight="medium"
                                            className="my-[2px] leading-[12px]"
                                        >
                                            ASIN added{" "}
                                        </PrimaryText>
                                    </div>

                                    {notification?.read_at === null ? (
                                        <div
                                            className="relative group"
                                            onMouseOver={() =>
                                                setHoveredIcon(
                                                    notification?.notification_id! && notification?.notification_id
                                                )
                                            }
                                            onMouseLeave={() => setHoveredIcon(null)}
                                            onClick={(e) => handleAsinNotificationRead(e, notification)}
                                        >
                                            {hoveredIcon === notification?.notification_id! &&
                                            notification?.notification_id ? (
                                                <NotificationRedIconHover />
                                            ) : (
                                                <NotificationRedIcon />
                                            )}
                                            <div
                                                style={{
                                                    boxShadow: "0px 2px 4px -2px #1018280F, 0px 4px 8px -2px #1018281A",
                                                }}
                                                className="opacity-0 min-w-[68px] absolute left-[-34px] top-[50%] translate-y-[-50%] z-50 transform translate-x-[-50%] group-hover:opacity-100 transition-all ease-in-out duration-300 bg-gray-400 text-[8px] leading-[11px] text-center font-[500] text-white py-[4px] px-[6px] rounded-[4px]"
                                            >
                                                Mark as read
                                            </div>
                                        </div>
                                    ) : (
                                        <div
                                            className="relative group"
                                            onMouseOver={() =>
                                                setHoveredIcon(
                                                    notification?.notification_id! && notification?.notification_id
                                                )
                                            }
                                            onMouseLeave={() => setHoveredIcon(null)}
                                            onClick={(e) => handleAsinNotificationRead(e, notification)}
                                        >
                                            {hoveredIcon === notification?.notification_id! &&
                                            notification?.notification_id ? (
                                                <NotificationGreyIconHover />
                                            ) : (
                                                <NotificationGreyIcon />
                                            )}
                                            <div
                                                style={{
                                                    boxShadow: "0px 2px 4px -2px #1018280F, 0px 4px 8px -2px #1018281A",
                                                }}
                                                className="opacity-0 min-w-[68px] absolute left-[-34px] top-[50%] px-[6px] translate-y-[-50%] z-50 transform translate-x-[-50%] group-hover:opacity-100 transition-all ease-in-out duration-300 bg-gray-400 text-[8px] leading-[11px] text-center font-[500] text-white py-[4px] rounded-[4px]"
                                            >
                                                Mark as unread
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="bg-transparent border-[#088AB2] border w-fit mt-[6px] mb-[2px] relative cursor-pointer rounded-[3px] px-[4px] py-[2.5px] h-4 flex gap-[3px] justify-center items-center">
                                    <PrimaryText
                                        className="text-[#088AB2] uppercase tracking-[0.7px]"
                                        weight="medium"
                                        size="xs-medium"
                                        onClick={() => handleProductRedirect(notification.requested_asin)}
                                    >
                                        {notification?.requested_asin}
                                    </PrimaryText>
                                    <div
                                        className="cursor-pointer"
                                        onClick={() => handleProductRedirect(notification.requested_asin)}
                                    >
                                        <LinkExternalSvg color={"#088AB2"} className="mb-[1px]" />
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div
                                className={`w-full cursor-pointer `}
                                onClick={() => {
                                    handleNotificationRead(
                                        notification?.read_at,
                                        notification.notification_id,
                                        notification.requested_asin,
                                        notification?.pd_timestamp
                                    )
                                }}
                            >
                                <div className=" flex items-center justify-between w-full">
                                    {AsinIssuesList.includes(notification.type!) ? (
                                        <div className="flex items-center gap-x-[4px] mt-[2px]">
                                            <WarningIcon />
                                            <PrimaryText
                                                size="xs-medium"
                                                weight="medium"
                                                className="leading-[12px] my-[2px]"
                                            >
                                                {getTooltipMessage(notification?.type)}{" "}
                                                {notification?.type === "LISTING_CHANGE" ||
                                                notification?.type === "DIFFERENT_ASIN_RETURNED" ||
                                                notification?.type === "CHILD_ASIN_RETURNED"
                                                    ? "returned"
                                                    : notification?.type === "DATA_UNAVAILABLE"
                                                    ? "unavailable"
                                                    : notification?.type === "DIFFERENT_LARGE_CATEGORY"
                                                    ? "Different"
                                                    : ""}
                                            </PrimaryText>
                                        </div>
                                    ) : (
                                        <div className="flex items-center gap-x-[4px] mt-[2px]">
                                            <SuccessIcon />
                                            <PrimaryText
                                                size="xs-medium"
                                                weight="medium"
                                                className="my-[2px] leading-[12px]"
                                            >
                                                ASIN added{" "}
                                            </PrimaryText>
                                        </div>
                                    )}

                                    {notification?.read_at === null ? (
                                        <div
                                            className="relative group"
                                            onMouseOver={() =>
                                                setHoveredIcon(
                                                    notification?.notification_id! && notification?.notification_id
                                                )
                                            }
                                            onMouseLeave={() => setHoveredIcon(null)}
                                            onClick={(e) => handleAsinNotificationRead(e, notification)}
                                        >
                                            {hoveredIcon === notification?.notification_id! &&
                                            notification?.notification_id ? (
                                                <NotificationRedIconHover />
                                            ) : (
                                                <NotificationRedIcon />
                                            )}
                                            <div
                                                style={{
                                                    boxShadow: "0px 2px 4px -2px #1018280F, 0px 4px 8px -2px #1018281A",
                                                }}
                                                className="opacity-0 min-w-[68px] absolute left-[-34px] top-[50%] translate-y-[-50%] z-50 transform translate-x-[-50%] group-hover:opacity-100 transition-all ease-in-out duration-300 bg-gray-400 text-[8px] leading-[11px] text-center font-[500] text-white py-[4px] px-[6px] rounded-[4px]"
                                            >
                                                Mark as read
                                            </div>
                                        </div>
                                    ) : (
                                        <div
                                            className="relative group"
                                            onMouseOver={() =>
                                                setHoveredIcon(
                                                    notification?.notification_id! && notification?.notification_id
                                                )
                                            }
                                            onMouseLeave={() => setHoveredIcon(null)}
                                            onClick={(e) => handleAsinNotificationRead(e, notification)}
                                        >
                                            {hoveredIcon === notification?.notification_id! &&
                                            notification?.notification_id ? (
                                                <NotificationGreyIconHover />
                                            ) : (
                                                <NotificationGreyIcon />
                                            )}
                                            <div
                                                style={{
                                                    boxShadow: "0px 2px 4px -2px #1018280F, 0px 4px 8px -2px #1018281A",
                                                }}
                                                className="opacity-0 min-w-[68px] absolute left-[-34px] top-[50%] px-[6px] translate-y-[-50%] z-50 transform translate-x-[-50%] group-hover:opacity-100 transition-all ease-in-out duration-300 bg-gray-400 text-[8px] leading-[11px] text-center font-[500] text-white py-[4px] rounded-[4px]"
                                            >
                                                Mark as unread
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="bg-transparent border-[#088AB2] border w-fit mt-[6px] mb-[2px] relative cursor-pointer rounded-[3px] px-[4px] py-[2.5px] h-4 flex gap-[3px] justify-center items-center">
                                    <PrimaryText
                                        className="text-[#088AB2] uppercase tracking-[0.7px]"
                                        weight="medium"
                                        size="xs-medium"
                                        onClick={() => handleProductRedirect(notification.requested_asin)}
                                    >
                                        {notification?.requested_asin}
                                    </PrimaryText>
                                    <div
                                        className="cursor-pointer"
                                        onClick={() => handleProductRedirect(notification.requested_asin)}
                                    >
                                        <LinkExternalSvg color={"#088AB2"} className="mb-[1px]" />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </>
    )
}

export default NotificationAsinCardByTrackpack
