import { useSelector } from "react-redux"
import { RunTimeStateSelector } from "../../../../selectors/RunTimeStateSelector"
import ListingAnalyzerReportFLowOutlet from "./lisitingAnalyzerReportFlowOutlet.component"
import { ListingAnalyzerWrapperModal } from "./listingAnalyzerWrapperModal.component"

const ListingAnalyzerModalMain = () => {
    const { listingReportModal } = useSelector(RunTimeStateSelector)

    const stepNumberMap: Record<string, number> = {
        one: 1,
        two: 2,
        three: 3,
        four: 4,
        five: 5,
        six: 6,
        seven: 7,
        eight: 8,
        nine: 9,
        ten: 10,
        eleven: 11,
        twelve: 12,
        thirteen: 13,
        fourteen: 14,
        fifteen: 15,
        sixteen: 16,
        seventeen: 17,
        eighteen: 18,
    }

    const stepNumber = stepNumberMap[listingReportModal?.stepNumber?.toLowerCase() || ""] || 0

    const modalWidth = stepNumber === 3 ? "w-[896px]" : stepNumber > 3 ? "w-[784px]" : "w-[960px]"

    return (
        <ListingAnalyzerWrapperModal
            style={{
                maxHeight: "calc(100% - 24px)",
                overflowY: "auto",
            }}
            className={`p-[48px] pb-[36px] absolute top-[50%] left-[50%] -translate-y-2/4 -translate-x-2/4 bg-white rounded-[24px] ${modalWidth}
            shadow-modal-shadow border-[1px] border-solid border-[#E7EBEF] transition-all`}
        >
            <ListingAnalyzerReportFLowOutlet />
        </ListingAnalyzerWrapperModal>
    )
}

export default ListingAnalyzerModalMain
