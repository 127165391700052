export const EditIcon = () => {
    return (
        <>
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M10.5 9L9.99994 9.54702C9.73473 9.83706 9.37507 10 9.00006 10C8.62505 10 8.2654 9.83706 8.00019 9.54702C7.73461 9.25756 7.37499 9.09503 7.00009 9.09503C6.62519 9.09503 6.26557 9.25756 5.99999 9.54702M1.5 9.99999H2.33727C2.58186 9.99999 2.70416 9.99999 2.81925 9.97236C2.92128 9.94786 3.01883 9.90746 3.1083 9.85263C3.20921 9.79079 3.29569 9.70431 3.46864 9.53136L9.75001 3.24999C10.1642 2.83578 10.1642 2.1642 9.75001 1.74999C9.3358 1.33578 8.66423 1.33578 8.25001 1.74999L1.96863 8.03136C1.79568 8.20431 1.7092 8.29079 1.64736 8.39171C1.59253 8.48118 1.55213 8.57872 1.52763 8.68076C1.5 8.79585 1.5 8.91814 1.5 9.16273V9.99999Z"
                    stroke="#98A2B3"
                    stroke-width="0.8"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        </>
    )
}
