import { useCallback, useEffect, useMemo, useState } from "react"
import ReactDOMServer from "react-dom/server"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { AccountActionsCreater } from "../../../actions/account.action"
import { NotificationsActionsCreator } from "../../../actions/notifications.action"
import { ProductsActionCreator } from "../../../actions/products.action"
import { RuntimeActionCreators } from "../../../actions/runTime.action"
import { ReactComponent as AddIcon } from "../../../assets/svgs/new-add-icon.svg"
import { RuntimeHelper } from "../../../helpers/runtime.helper"
import { getlatestProductId } from "../../../helpers/util.helper"
import { AccountStateSelector } from "../../../selectors/accountState.selector"
import { NotificationStateSelector } from "../../../selectors/notificationStateSelector"
import { ProductStateSelector } from "../../../selectors/product.selector"
import { RunTimeStateSelector } from "../../../selectors/RunTimeStateSelector"
import { ButtonElement } from "../../elements/button.element"
import { PrimaryText } from "../../elements/primaryText.element"
import { SelectElement } from "../../elements/select.element"
import { AsinContrastCard } from "../asinCard.component"
import CommonTooltip from "../tooltipItems/commonTooltip.component"

export interface Root {
    is_new_data_available: boolean
}
let getNewData: any = null

export const GroupOptions = () => {
    const { control, watch, setValue } = useForm()
    const dispatch = useDispatch()
    const asinsValue = useMemo(() => watch("asins"), [watch("asins")])
    const { loading } = useSelector(RunTimeStateSelector)
    const isLoading: boolean = useMemo(() => RuntimeHelper.trackpackListLoading(), [loading])
    const { userActionData } = useSelector(AccountStateSelector)
    const {
        selectedOption,
        selectedDropdownList,
        selectedGroupProducts,
        trackpackDropdownList,
        selectedTrackpackValues,
        selectedTrackpackData,
    } = useSelector(ProductStateSelector)
    const [defaultTrackpack, setDefaultTrackpack] = useState<string | undefined>()
    const [allTrackpacks, setAllTrackpacks] = useState<any>()
    const [selectedIndex, setSelectedIndex] = useState<number>()
    const [amazontld, setAmazontld] = useState<string>()
    let multiSelectProduct = selectedOption !== "Log"

    const selectedTrackpackID = selectedTrackpackValues?.selectedTrackpackID

    const { notifiSelection } = useSelector(NotificationStateSelector)

    function hasKey(obj: any, key: string) {
        return obj?.hasOwnProperty(key)
    }

    const allTrackingsAwaitingInitialData =
        selectedTrackpackData?.trackings?.every(
            (tracking: { status: string }) =>
                tracking.status === "AWAITING_INITIAL_DATA" ||
                tracking.status === "ARCHIVED" ||
                tracking.status === "ARCHIVED_DURING_PACK_ARCHIVAL"
        ) &&
        selectedTrackpackData?.trackings?.filter(
            (tracking: { status: string }) => tracking.status === "AWAITING_INITIAL_DATA"
        ).length >= 1

    const checkNewProductData = (id: string | null) => {
        if (!id) return

        dispatch(
            ProductsActionCreator.checkNewProductDataAvailable(
                id,
                {},
                { product_id: localStorage.getItem("product_id")! },
                (data: Root) => {
                    if (data?.is_new_data_available) {
                        if (allTrackingsAwaitingInitialData) {
                            // debouncedGettrackpackData()

                            getProductsByGroupWithUser(selectedTrackpackID!)
                        } else {
                            setTimeout(() => {
                                dispatch(
                                    RuntimeActionCreators.openNotificaitonAlert({
                                        open: true,
                                        notificationType: "new_Data",
                                        duration: 30000,
                                    })
                                )
                            }, 100)
                        }
                    }
                }
            )
        )
    }

    useEffect(() => {
        if (getNewData) {
            clearInterval(getNewData)
        }
        getNewData = setInterval(
            () => {
                checkNewProductData(selectedTrackpackID!)
            },
            5 * 60 * 1000
        )
        return () => clearInterval(getNewData)
    }, [selectedTrackpackID, allTrackingsAwaitingInitialData])

    const debounce = (func: Function, delay: number) => {
        let timeoutId: ReturnType<typeof setTimeout>
        return (...args: any[]) => {
            if (timeoutId) {
                clearTimeout(timeoutId)
            }
            timeoutId = setTimeout(() => {
                func(...args)
            }, delay)
        }
    }

    const debouncedGetProductsByGroupWithUser = useCallback(
        debounce((id: string) => {
            getProductsByGroupWithUser(id)
        }, 500),
        []
    )
    // Effect to set selectedTrackPackID if it doesn't exist in localStorage
    // useEffect(() => {
    //     if (!selectedTrackpackID && selectedIndex !== undefined) {
    //         const newTrackpackID = selectedIndex.toString()
    //         dispatch(ProductsActionCreator.setSelectedTrackPackValues({ selectedTrackpackID: newTrackpackID }))
    //     }
    // }, [selectedIndex, selectedTrackpackID])

    useEffect(() => {
        if (selectedTrackpackID) {
            if (hasKey(selectedGroupProducts?.groupProductData, selectedTrackpackID)) {
                // const previousData = selectedGroupProducts?.groupProductData[selectedTrackpackID] || []
                // dispatch(
                //     ProductsActionCreator.selectedProducts({
                //         // selectedProducts: [previousData?.[0]],
                //         selectedProducts: [],
                //     })
                // )
            } else {
                // dispatch(
                //     ProductsActionCreator.getExtendedTrackingData(
                //         selectedTrackpackID,
                //         {},
                //         { only_active_trackings: true }
                //     )
                // )
                debouncedGetProductsByGroupWithUser(selectedTrackpackID)
                dispatch(
                    ProductsActionCreator.selectedProducts({
                        selectedProducts: [],
                    })
                )
            }
        }
    }, [selectedTrackpackID])

    useEffect(() => {
        if (isLoading) return
        if (trackpackDropdownList?.trackpacks && trackpackDropdownList?.trackpacks?.length > 0) {
            const transformedResponse = {
                groups: trackpackDropdownList.trackpacks.map((group: any) => ({
                    name: group.name,
                    id: group.id,
                    amazon_tld: group.amazon_tld,
                })),
            }
            const defaultGroupID = transformedResponse.groups.find((group: any) => group.id == selectedTrackpackID)
            const defaultGroup = defaultGroupID ?? transformedResponse.groups[0]
            setAllTrackpacks(transformedResponse as any)
            setDefaultTrackpack(defaultGroup?.id)
            setSelectedIndex(defaultGroup?.id)
            setAmazontld(defaultGroup?.amazon_tld)
            setValue("asins", defaultGroup?.id)
        } else {
            setValue("asins", undefined)
            setAllTrackpacks([])
            setDefaultTrackpack("")
            setSelectedIndex(undefined)
        }
    }, [trackpackDropdownList, setValue, userActionData, selectedTrackpackID])

    const getProductsByGroupWithUser = (groupID: string) => {
        const options: { as_user?: string; since?: string } = {}

        const currentDate = new Date()
        const sixMonthsAgo = new Date()
        sixMonthsAgo.setMonth(currentDate.getMonth() - 6)
        const sinceDate = sixMonthsAgo?.toISOString().split("T")[0]

        options.since = sinceDate

        if (userActionData) {
            options.as_user = userActionData.id + ""
        }
        dispatch(ProductsActionCreator.getProductsByGroup(groupID, amazontld, {}, options, false))
    }

    function checkNotification(
        selectedGroupProducts: IGroupProductDataResponse | undefined,
        selectedTrackpackID: string | number,
        notifiSelection: {
            selectedTrackpackID: any
            notifiAsin: any[]
            timeStamp: string | Date
            createdAt: string | Date
        }
    ) {
        if (
            (selectedTrackpackID && selectedTrackpackID) === (notifiSelection && notifiSelection?.selectedTrackpackID)
        ) {
            const groupProductData =
                selectedGroupProducts?.groupProductData &&
                selectedGroupProducts?.groupProductData?.[selectedTrackpackID]

            if (groupProductData) {
                for (let product of groupProductData) {
                    if (product.actual_asin === notifiSelection?.notifiAsin?.[0]) {
                        const notificationTimestamp = new Date(notifiSelection.createdAt).getTime()
                        // const productTimestamp = new Date(product.main_product_data.timestamp).getTime()
                        const productTimestamp = product?.main_product_data?.timestamp
                            ? new Date(product?.main_product_data.timestamp).getTime()
                            : ""

                        // @ts-ignore
                        const timeDifference = notificationTimestamp - productTimestamp

                        const fiveMinutesInMs = 5 * 60 * 1000

                        if (timeDifference > fiveMinutesInMs) {
                            return true
                        }
                    }
                }
            }
        }
        return false
    }

    const isNewDataAvailable = checkNotification(selectedGroupProducts, selectedTrackpackID!, notifiSelection)

    useEffect(() => {
        if (!isNewDataAvailable) return
        if (
            selectedDropdownList?.trackpacks &&
            selectedDropdownList?.trackpacks?.length > 0 &&
            selectedTrackpackID &&
            isNewDataAvailable
        ) {
            if (hasKey(selectedGroupProducts?.groupProductData, selectedTrackpackID)) {
                const latestProductId = getlatestProductId(selectedGroupProducts!.groupProductData[selectedTrackpackID])
                dispatch(
                    ProductsActionCreator.getProductsByGroup(
                        selectedTrackpackID,
                        "",
                        {},
                        latestProductId ? { product_id: latestProductId.toString() } : {},
                        true
                    )
                )
            }
        }
    }, [isNewDataAvailable, selectedTrackpackID])

    const handleOpen = () => {
        dispatch(
            RuntimeActionCreators.openTrackPackFlowModal({
                open: true,
            })
        )
    }

    useEffect(() => {
        if (selectedTrackpackID) {
            setDefaultTrackpack(selectedTrackpackID)
            setSelectedIndex(Number(selectedTrackpackID))
            setValue("asins", selectedTrackpackID)
        }
    }, [selectedTrackpackID, setValue])

    useEffect(() => {
        dispatch(AccountActionsCreater.setSelectedTrackpackList(selectedIndex))
    }, [asinsValue])

    const handleSelectElementChange = (selectedValue: string) => {
        dispatch(NotificationsActionsCreator.notifiSelection(structuredClone({})))
        dispatch(
            ProductsActionCreator.selectedProducts({
                selectedProducts: [],
            })
        )

        const selectedGroup = allTrackpacks.groups.find((group: any) => group.id === selectedValue)

        if (selectedGroup) {
            setSelectedIndex(selectedGroup.id)
            dispatch(
                ProductsActionCreator.getExtendedTrackingData(selectedGroup.id, {}, { only_active_trackings: true })
            )
            dispatch(
                ProductsActionCreator.setSelectedTrackPackValues({
                    selectedTrackpackID: String(selectedGroup.id),
                })
            )
        }
    }

    // const handleSelectElementChange = (selectedValue: string) => {
    //     dispatch(NotificationsActionsCreator.notifiSelection(structuredClone({})))
    //     dispatch(
    //         ProductsActionCreator.selectedProducts({
    //             selectedProducts: [],
    //         })
    //     )
    //     const index = allTrackpacks.groups.find((group: any) => {
    //         return group.id === selectedValue
    //     })
    //     setSelectedIndex(index?.id)
    //     dispatch(ProductsActionCreator.setSelectedTrackPackID(index?.id))
    // }

    // useEffect(() => {
    //     if (selectedIndex !== undefined && allTrackpacks?.groups?.length > 0) {
    //         localStorage.removeItem("defaultSelectedAsin")
    //     }
    // }, [selectedIndex, allTrackpacks])

    return (
        <>
            <div className="flex flex-col gap-[24px] w-[304px]">
                <div className="w-full">
                    <div className="w-full p-[12px] px-3 pt-3 justify-start items-start inline-flex rounded-[16px]  border border-gray-50 bg-gray-50 ">
                        <div className="w-full gap-[12px]  flex flex-col">
                            <div className="flex justify-between ">
                                <div className="flex gap-[6px] items-center">
                                    <PrimaryText
                                        // onClick={handleOpenTrackPack}
                                        weight="medium"
                                        size="sm-medium"
                                        className="text-[#088AB2] font- leading-normal pl-[8px]"
                                    >
                                        Trackpacks
                                    </PrimaryText>
                                    {/* <InfoLabel /> */}
                                </div>
                                <div
                                    className="cursor-pointer outline-none"
                                    data-tooltip-id="dashboard-tooltip"
                                    data-tooltip-place="bottom"
                                    data-tooltip-offset={-10}
                                    data-tooltip-class-name={"!ml-[38px]"}
                                    data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                                        <CommonTooltip className="" label="Add Trackpack" />
                                    )}
                                >
                                    <ButtonElement
                                        onClick={handleOpen}
                                        viewType="only-icon"
                                        className="p-[10px] bg-gradient-to-br from-cyan-100 to-cyan-200 rounded-[10px] disabled:bg-gray-100
                                    hover:bg-gradient-to-r hover:from-cyan-200 hover:to-cyan-300
                                    active:bg-gradient-to-br active:from-cyan-300 active:to-cyan-400"
                                    >
                                        <AddIcon />
                                    </ButtonElement>
                                </div>
                            </div>
                            <div className="w-[280px] relative">
                                <SelectElement
                                    className="focus:outline focus:outline-cyan-300/30 pr-[8px] !w-[99%]"
                                    customBoxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px rgba(103, 227, 249, 0.20)"
                                    buttonTextClass="truncate whitespace-nowrap max-w-[220px] "
                                    reactHookControl={control}
                                    type="check"
                                    name="asins"
                                    disabled={isLoading}
                                    defaultValue={defaultTrackpack}
                                    rightCheckBox={true}
                                    isToolitpShow={false}
                                    options={
                                        allTrackpacks &&
                                        allTrackpacks?.groups?.map(
                                            (g: { id: string; name: string; amazon_tld: string }) => {
                                                return {
                                                    value: g?.id,
                                                    label: g?.name,
                                                    amazon_tld: g?.amazon_tld,
                                                }
                                            }
                                        )
                                    }
                                    onChange={handleSelectElementChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <AsinContrastCard multipleProductSelected={multiSelectProduct} />
            </div>
            {/* {(getAccessLevel?.first_name || getAccessLevel?.username) && <TrackPackChatModal />} */}
        </>
    )
}
