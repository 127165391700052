import { useEffect, useMemo, useRef, useState } from "react"
import ReactDOMServer from "react-dom/server"
import Skeleton from "react-loading-skeleton"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useSearchParams } from "react-router-dom"
import { AccountActionsCreater } from "../../../actions/account.action"
import { ProductsActionCreator } from "../../../actions/products.action"
import { RuntimeActionCreators } from "../../../actions/runTime.action"
import { CheckedIcon } from "../../../assets/svgs/checkedIcon.svg"
import { ReactComponent as CrossIcon } from "../../../assets/svgs/cross.icon.svg"
import { ReactComponent as RoundedAddIcon } from "../../../assets/svgs/custom-add-icon.svg"
import { FilterIcon } from "../../../assets/svgs/filterIcon.svg"
import { FlipArrowIcon } from "../../../assets/svgs/flipBackwardIcon.svg"
import { ReactComponent as AddIcon } from "../../../assets/svgs/new-add-icon.svg"
import { TrackpackTrashIcon } from "../../../assets/svgs/trashIcon.svg"
import { img_url } from "../../../config/dashboard.config"
import { ROUTES_CONFIG } from "../../../config/routes.config"
import { calculateDays, getASINCount } from "../../../helpers/util.helper"
import useGetExtendedTrackpacks from "../../../hooks/reactQueryHooks.hook"
import { AccountStateSelector } from "../../../selectors/accountState.selector"
import { RunTimeStateSelector } from "../../../selectors/RunTimeStateSelector"
import CardSkeletonLoader from "../../common/skeletonLoader/cardSkeleton.component"
import { ButtonElement } from "../../elements/button.element"
import { PrimaryText } from "../../elements/primaryText.element"
import { AMAZON_DOMAINS } from "../../marketing/domains.config"
import CommonTooltip from "../tooltipItems/commonTooltip.component"

interface TrackpackProps {
    setSelectedRows: React.Dispatch<React.SetStateAction<string[]>>
}

export interface Root {
    ASINs: Asins
    Status: string[]
    Owner: string
    "Date Added": string
    time_deletion: number
    amazon_tld: string
    currentIssue: any
}

export interface Asins {
    asinNo: string
    asinText: string
    imgUrl: string
}

export const TrackpackOptions = ({ setSelectedRows }: TrackpackProps) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { userActionData, selectedTrackpacksList } = useSelector(AccountStateSelector)
    const { loading } = useSelector(RunTimeStateSelector)

    const [infoDisplay, setInfoDisplay] = useState(true)
    const [selectedIndex, setSelectedIndex] = useState<number>(0)
    const [isScrollable, setIsScrollable] = useState(false)
    const containerRef = useRef(null)
    const [trackpackType, setTrackpackType] = useState<string>("tracking")
    const [isOpen, setIsOpen] = useState(false)
    const [selectedItem, setSelectedItem] = useState<string>("Newest")

    const pathID = localStorage.getItem("trackpackHubId")
    const [searchParams] = useSearchParams()
    const id = searchParams.get("id")

    const pathParams = {}

    const queryParams = userActionData ? { as_user: userActionData.id.toString() } : {}

    const { data, isLoading, refetch } = useGetExtendedTrackpacks(pathParams, queryParams)

    useEffect(() => {
        if (id) {
            localStorage.setItem("trackpackHubId", id)
            navigate(ROUTES_CONFIG.trackpackCentral)
        }
    }, [])

    const convertedTrackpacks = useMemo(() => {
        let trackpacks = []

        if (Array.isArray(data)) {
            trackpacks = data
        } else if (data?.trackpacks) {
            trackpacks = data.trackpacks
        } else {
            return []
        }

        return trackpacks.map((trackpack: any) => {
            const id = trackpack.metadata.id
            const title = trackpack.metadata.name
            const status = trackpack.metadata.status
            const imageSources = trackpack.trackings
                .filter((tracking: any) => tracking.status !== "ARCHIVED")
                .map((tracking: any) => tracking?.latest_data?.main_image_filename)
            const amazonDomain = trackpack.metadata.amazon_tld

            let userASINs = 0
            let competitorASINs = 0
            let archived = 0
            if (trackpackType === "archived") {
                userASINs = trackpack.trackings.filter(
                    (tracking: any) =>
                        tracking.status === "ARCHIVED_DURING_PACK_ARCHIVAL" && tracking.own_product === true
                ).length

                competitorASINs = trackpack.trackings.filter(
                    (tracking: any) =>
                        tracking.status === "ARCHIVED_DURING_PACK_ARCHIVAL" && tracking.own_product === false
                ).length

                archived = trackpack.trackings.filter((tracking: any) => tracking.status === "ARCHIVED").length
            } else {
                userASINs = trackpack.trackings.filter(
                    (tracking: any) =>
                        (tracking.status === "ACTIVE" || tracking.status === "AWAITING_INITIAL_DATA") &&
                        tracking.own_product === true
                ).length

                competitorASINs = trackpack.trackings.filter(
                    (tracking: any) =>
                        (tracking.status === "ACTIVE" || tracking.status === "AWAITING_INITIAL_DATA") &&
                        tracking.own_product === false
                ).length
                archived = trackpack.trackings.filter(
                    (tracking: any) =>
                        tracking.status === "ARCHIVED" || tracking.status === "ARCHIVED_DURING_PACK_ARCHIVAL"
                ).length
            }

            const trackpackChildrenList = trackpack.trackings.map((tracking: any) => {
                const ASINs = {
                    asinNo: tracking.asin,
                    asinText: tracking?.latest_data?.title,
                    imgUrl: tracking?.latest_data?.main_image_filename,
                }

                const Status = [tracking.status]
                const currentIssue = tracking.current_issue

                let Owner = ""

                if (tracking.own_product) {
                    const firstName = trackpack?.metadata?.user_first_name
                    const initials = `${firstName.charAt(0)}`
                    Owner = initials
                }

                const DateAdded = tracking.datetime_from.substring(0, 10)
                const timeDeletion = calculateDays(tracking.datetime_from)
                const amazon_tld = trackpack?.metadata?.amazon_tld

                return {
                    ASINs,
                    Status,
                    Owner,
                    "Date Added": DateAdded,
                    time_deletion: timeDeletion,
                    amazon_tld,
                    currentIssue,
                }
            })

            const actionCount = getASINCount(trackpackChildrenList?.length)

            return {
                title,
                userASINs,
                competitorASINs,
                actionCount,
                trackpackChildrenList,
                id,
                status,
                archived,
                imageSources,
                amazonDomain,
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, trackpackType])

    const trackpackTypeListView =
        trackpackType === "tracking"
            ? convertedTrackpacks &&
              convertedTrackpacks?.filter((val: { status: string | string[] }) => {
                  return val?.status?.includes("ACTIVE") || val?.status?.includes("AWAITING_INITIAL_DATA")
              })
            : convertedTrackpacks &&
              convertedTrackpacks?.filter((val: { status: string | string[] }) => {
                  return val?.status?.includes("ARCHIVED") || val?.status?.includes("ARCHIVED_DURING_PACK_ARCHIVAL")
              })

    useEffect(() => {
        if (trackpackTypeListView?.length > 0) {
            let indexToSelect = 0

            if (pathID) {
                const foundIndex = trackpackTypeListView.findIndex((trackpack: any) => trackpack.id == pathID)
                indexToSelect = foundIndex !== -1 ? foundIndex : 0
            } else {
                indexToSelect =
                    selectedIndex !== undefined && selectedIndex < trackpackTypeListView.length ? selectedIndex : 0
            }

            dispatch(AccountActionsCreater.setSelectedTrackpackList(trackpackTypeListView[indexToSelect]))
            setSelectedIndex(indexToSelect)
        }
        if (pathID && convertedTrackpacks && convertedTrackpacks.length > 0) {
            const matchingTrackpack = convertedTrackpacks.find((val: { id: string }) => val.id == pathID)
            if (matchingTrackpack) {
                const trackpackStatus = matchingTrackpack.status

                if (trackpackStatus === "ACTIVE" || trackpackStatus === "AWAITING_INITIAL_DATA") {
                    setTrackpackType("tracking")
                } else if (trackpackStatus === "ARCHIVED" || trackpackStatus === "ARCHIVED_DURING_PACK_ARCHIVAL") {
                    setTrackpackType("archived")
                }
            }
            if (!isLoading) {
                setTimeout(() => {
                    localStorage.removeItem("trackpackHubId")
                }, 6000)
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, convertedTrackpacks, trackpackType, pathID])

    useEffect(() => {
        const checkScrollability = () => {
            if (containerRef.current) {
                const { scrollHeight, clientHeight } = containerRef.current
                setIsScrollable(scrollHeight > clientHeight)
            }
        }

        checkScrollability()
        const currentContainer = containerRef.current
        const resizeObserver = new ResizeObserver(checkScrollability)
        if (currentContainer) {
            resizeObserver.observe(currentContainer)
        }

        return () => {
            if (currentContainer) {
                resizeObserver.unobserve(currentContainer)
            }
        }
    }, [])

    const handleSelectedOptions = (selectedOptions: any) => {
        dispatch(AccountActionsCreater.setSelectedTrackpackList(selectedOptions))
    }

    const toggleDropdown = () => {
        setIsOpen(!isOpen)
    }

    const handleItemClick = (item: string) => {
        setSelectedItem(item)
        setIsOpen(false)
    }

    const handleOpen = () => {
        dispatch(
            RuntimeActionCreators.openTrackPackFlowModal({
                open: true,
            })
        )
    }

    const handleOpenArchive = () => {
        dispatch(
            RuntimeActionCreators.openArchiveModal({
                open: true,
                stepNumber: "one",
                isArchiveShow: false,
            })
        )
    }

    const hasOnlyActiveOrAwaiting = (trackpacks: any[]) => {
        return trackpacks.every((val) => val.status.includes("ACTIVE") || val.status.includes("AWAITING_INITIAL_DATA"))
    }

    const isArchiveDisabled = useMemo(() => {
        return hasOnlyActiveOrAwaiting(convertedTrackpacks)
    }, [convertedTrackpacks])

    const hasOnlyArchivedOrAwaiting = (trackpacks: any[]) => {
        return trackpacks.every(
            (val) => val.status.includes("ARCHIVED") || val.status.includes("ARCHIVED_DURING_PACK_ARCHIVAL")
        )
    }

    const isTrackingDisabled = useMemo(() => {
        return hasOnlyArchivedOrAwaiting(convertedTrackpacks)
    }, [convertedTrackpacks])

    useEffect(() => {
        if (isArchiveDisabled) {
            setTrackpackType("tracking")
        } else if (isTrackingDisabled) {
            setTrackpackType("archived")
        }
    }, [isArchiveDisabled, isTrackingDisabled])

    const trackpackRefs = useRef<(HTMLDivElement | null)[]>([])

    useEffect(() => {
        if (pathID && data && trackpackRefs.current[selectedIndex]) {
            trackpackRefs.current[selectedIndex]?.scrollIntoView({ behavior: "smooth", block: "center" })
        }
    }, [selectedIndex, pathID, data])

    const handleRestoreTrackpack = (product: any) => {
        dispatch(
            ProductsActionCreator.restoreTrackpack(
                {
                    trackpack_id: product.id,
                },
                (data: Root) => {
                    refetch()
                    dispatch(
                        RuntimeActionCreators.openNotificaitonAlert({
                            open: true,
                            notificationType: "restore",
                            trackpackName: product?.title,
                            duration: 30000,
                            id: product.id,
                            isAsinRestore: false,
                        })
                    )
                }
            )
        )
    }

    return (
        <>
            <div
                className="w-[304px]  p-[12px]  rounded-[16px] bg-gray-50 h-calc-163"
                style={{
                    border: "1px solid",
                    borderImageSource: "linear-gradient(180deg, #F9FAFB 0%, #E7EBEF 100%)",
                }}
            >
                <div>
                    <div className="flex justify-between ">
                        <div className="flex gap-[4px] items-center ml-[8px]">
                            <PrimaryText weight="medium" size="sm-medium" className="text-[#088AB2] leading-normal">
                                Trackpacks
                            </PrimaryText>
                            {/* <InfoLabel /> */}
                        </div>
                        <div
                            className="cursor-pointer outline-none"
                            data-tooltip-id="dashboard-tooltip"
                            data-tooltip-place="bottom"
                            data-tooltip-offset={-10}
                            data-tooltip-class-name={"!ml-[38px]"}
                            data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                                <CommonTooltip className="" label="Add Trackpack" />
                            )}
                        >
                            <ButtonElement
                                onClick={handleOpen}
                                viewType="only-icon"
                                className="p-[10px] cursor-pointer bg-gradient-to-br from-cyan-100 to-cyan-200 rounded-[10px] disabled:bg-gray-100
                                    hover:bg-gradient-to-r hover:from-cyan-200 hover:to-cyan-300
                                    active:bg-gradient-to-br active:from-cyan-300 active:to-cyan-400"
                            >
                                <AddIcon />
                            </ButtonElement>
                        </div>
                    </div>
                    <div className="flex items-center justify-between w-full mt-[24px] mb-[16px] relative">
                        <div className="flex ml-[8px] border border-gray-200 rounded-[8px] w-[108px] h-[16px]">
                            <div
                                className={`overflow-hidden rounded-tl-[8px] rounded-bl-[8px] flex items-center justify-center pl-[7px] pr-[6px] py-[2px] border-r border-gray-200 h-full ${
                                    trackpackType === "tracking"
                                        ? "bg-[#DCFAE6]"
                                        : isTrackingDisabled
                                        ? "bg-gray-100"
                                        : "bg-white"
                                }`}
                            >
                                <PrimaryText
                                    onClick={() => {
                                        !isTrackingDisabled && setTrackpackType("tracking")
                                        // dispatch(AccountActionsCreater.setTrackpackType("tracking"))
                                        dispatch(AccountActionsCreater.setSelectedAsinsLength(0))
                                        setSelectedRows([])
                                    }}
                                    weight={trackpackType === "tracking" ? "medium" : "light"}
                                    size="xs-small"
                                    className={`${isTrackingDisabled ? "cursor-not-allowed" : "cursor-pointer"} ${
                                        trackpackType === "tracking" ? "text-[#067647] " : "text-[#344054]"
                                    }  leading-[12px] h-full align-middle`}
                                >
                                    TRACKING
                                </PrimaryText>
                            </div>
                            <div
                                className={`overflow-hidden rounded-tr-[8px] rounded-br-[8px] flex items-center justify-center pl-[7px] pr-[6px] py-[2px] ${
                                    trackpackType === "archived"
                                        ? "bg-[#FEE4E2]"
                                        : isArchiveDisabled
                                        ? "bg-gray-100"
                                        : "bg-white"
                                }`}
                            >
                                <PrimaryText
                                    onClick={() => {
                                        !isArchiveDisabled && setTrackpackType("archived")
                                        // dispatch(AccountActionsCreater.setTrackpackType("archived"))
                                        dispatch(AccountActionsCreater.setSelectedAsinsLength(0))
                                        setSelectedRows([])
                                    }}
                                    weight={trackpackType === "archived" ? "medium" : "light"}
                                    size="xs-small"
                                    className={` ${
                                        trackpackType === "archived" ? "text-[#B42318]" : "text-[#344054]"
                                    } ${
                                        isArchiveDisabled ? "cursor-not-allowed" : "cursor-pointer"
                                    }  leading-[12px] h-full align-middle`}
                                >
                                    ARCHIVED
                                </PrimaryText>
                            </div>
                        </div>
                        <div className="mr-[6px] hidden" onClick={toggleDropdown}>
                            <FilterIcon />
                        </div>
                        {isOpen && (
                            <div
                                className="absolute cursor-pointer right-0 top-[100%] w-[180px]  bg-white rounded-[10px] p-[6px]"
                                style={{
                                    boxShadow: "0px 4px 6px -2px #10182808, 0px 12px 16px -4px #10182814",
                                    border: "1px solid",
                                    borderImageSource: "linear-gradient(180deg, #F9FAFB 0%, #E7EBEF 100%)",
                                }}
                            >
                                <ul className="p-0 m-0">
                                    <li
                                        className={`flex items-center justify-between rounded-[4px] p-[8px] ${
                                            selectedItem === "Newest" ? "bg-[#F2F4F7]" : ""
                                        }`}
                                        onClick={() => handleItemClick("Newest")}
                                    >
                                        <PrimaryText
                                            weight={selectedItem === "Newest" ? "medium" : "light"}
                                            size="sm-medium"
                                            className="text-gray-700  cursor-pointer"
                                        >
                                            Newest
                                        </PrimaryText>

                                        {selectedItem === "Newest" && <CheckedIcon />}
                                    </li>
                                    <li
                                        className={`flex items-center justify-between rounded-[4px] p-[8px] ${
                                            selectedItem === "Oldest" ? "bg-[#F2F4F7]" : ""
                                        }`}
                                        onClick={() => handleItemClick("Oldest")}
                                    >
                                        <PrimaryText
                                            weight={selectedItem === "Oldest" ? "medium" : "light"}
                                            size="sm-medium"
                                            className="text-gray-700  cursor-pointer"
                                        >
                                            Oldest
                                        </PrimaryText>

                                        {selectedItem === "Oldest" && <CheckedIcon />}
                                    </li>
                                    <li
                                        className={`flex items-center justify-between rounded-[4px] p-[8px] ${
                                            selectedItem === "A to Z" ? "bg-[#F2F4F7]" : ""
                                        }`}
                                        onClick={() => handleItemClick("A to Z")}
                                    >
                                        <PrimaryText
                                            weight={selectedItem === "A to Z" ? "medium" : "light"}
                                            size="sm-medium"
                                            className="text-gray-700  cursor-pointer"
                                        >
                                            A to Z
                                        </PrimaryText>

                                        {selectedItem === "A to Z" && <CheckedIcon />}
                                    </li>
                                    <li
                                        className={`flex items-center justify-between rounded-[4px] p-[8px] ${
                                            selectedItem === "Z to A" ? "bg-[#F2F4F7]" : ""
                                        }`}
                                        onClick={() => handleItemClick("Z to A")}
                                    >
                                        <PrimaryText
                                            weight={selectedItem === "Z to A" ? "medium" : "light"}
                                            size="sm-medium"
                                            className="text-gray-700  cursor-pointer"
                                        >
                                            Z to A
                                        </PrimaryText>

                                        {selectedItem === "Z to A" && <CheckedIcon />}
                                    </li>
                                </ul>
                            </div>
                        )}
                    </div>
                    {/* {!isScrollable && infoDisplay && <div className="bg-gray-300 h-[1px] w-full mb-[16px]"></div>} */}
                    <div
                        ref={containerRef}
                        className={`overflow-y-scroll -mr-[24px] ${
                            !infoDisplay ? "h-custom flex flex-col gap-[8px]" : "min-h-calc-271 h-calc-271"
                        }`}
                    >
                        {isLoading ? (
                            <CardSkeletonLoader length={6} isTrackpack={true} />
                        ) : (
                            <div
                                className="flex-col gap-[8px] inline-flex w-full"
                                style={{
                                    width: "calc(100% - 20px)",
                                }}
                            >
                                {trackpackTypeListView &&
                                    trackpackTypeListView?.map((product: any, index: number) => {
                                        const hasCurrentIssue = product.trackpackChildrenList?.some(
                                            (child: Root) => child.currentIssue !== null
                                        )
                                        const selected_country = AMAZON_DOMAINS?.find((val) => {
                                            return val?.value === product.amazonDomain
                                        })
                                        return (
                                            <div
                                                key={index}
                                                ref={(el) => (trackpackRefs.current[index] = el)}
                                                onClick={() => {
                                                    setSelectedIndex(index)
                                                    handleSelectedOptions(product)
                                                    setSelectedRows([])
                                                }}
                                                className={`
                                        ${
                                            index === selectedIndex
                                                ? `${
                                                      trackpackType === "archived"
                                                          ? "border-[2px] border-[#F04438]"
                                                          : hasCurrentIssue
                                                          ? "border-[2px] border-[#F79009]"
                                                          : "border-[2px] border-[#17B26A]"
                                                  }`
                                                : "border border-gray-300"
                                        }
                                        last-child pl-[10px] py-[10px] pr-[8px] bg-white rounded-[10px] cursor-pointer max-w-[280px] w-auto -outline-offset-2
                                    `}
                                            >
                                                <div className="justify-between flex w-full">
                                                    <div>
                                                        <PrimaryText
                                                            size="xs"
                                                            weight="medium"
                                                            className="text-gray-700 leading-[12px] line-clamp-1 min-h-[13px] max-w-[28ch]"
                                                        >
                                                            {product.title}
                                                        </PrimaryText>
                                                        <div className="flex gap-[5px] h-[8px] mt-[4px]">
                                                            <PrimaryText
                                                                size="xs-small"
                                                                weight="medium"
                                                                className={` ${
                                                                    trackpackType !== "archived" && hasCurrentIssue
                                                                        ? "text-[#F79009]"
                                                                        : trackpackType === "tracking"
                                                                        ? "text-[#17B26A]"
                                                                        : "text-[#F04438]"
                                                                } !text-[9px]`}
                                                            >
                                                                ASINs:
                                                            </PrimaryText>
                                                            <PrimaryText
                                                                size="xs-small"
                                                                weight="light"
                                                                className="text-gray-700 !text-[9px] "
                                                            >
                                                                {product.userASINs} Users
                                                            </PrimaryText>
                                                            <PrimaryText
                                                                className={` ${
                                                                    hasCurrentIssue
                                                                        ? "text-[#F79009]"
                                                                        : trackpackType === "tracking"
                                                                        ? "text-[#17B26A]"
                                                                        : "text-[#F04438]"
                                                                } text-[9px] `}
                                                            >
                                                                •
                                                            </PrimaryText>
                                                            <PrimaryText
                                                                size="xs-small"
                                                                weight="light"
                                                                className="text-slate-700 !text-[9px] "
                                                            >
                                                                {product.competitorASINs} Competitors
                                                            </PrimaryText>
                                                            <PrimaryText
                                                                className={` ${
                                                                    hasCurrentIssue
                                                                        ? "text-[#F79009]"
                                                                        : trackpackType === "tracking"
                                                                        ? "text-[#17B26A]"
                                                                        : "text-[#F04438]"
                                                                } text-[9px] `}
                                                            >
                                                                •
                                                            </PrimaryText>
                                                            <PrimaryText
                                                                size="xs-small"
                                                                weight="light"
                                                                className="text-slate-700 !text-[9px]"
                                                            >
                                                                {product.archived} Archived
                                                            </PrimaryText>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className={`w-auto h-[16px] pl-[6px] pr-[3px] ${
                                                            trackpackType !== "archived" && hasCurrentIssue
                                                                ? "bg-[#FFFAEB]"
                                                                : trackpackType === "tracking"
                                                                ? "bg-[#ECFDF3]"
                                                                : "bg-[#FEF3F2]"
                                                        }  rounded-[16px] outline outline-1 ${
                                                            trackpackType !== "archived" && hasCurrentIssue
                                                                ? "outline-[#FEDF89]"
                                                                : trackpackType === "tracking"
                                                                ? "outline-[#ABEFC6]"
                                                                : "outline-[#FECDCA]"
                                                        }  flex items-center gap-[4px]`}
                                                    >
                                                        <PrimaryText
                                                            weight="book"
                                                            size="xs-medium"
                                                            className={` ${
                                                                trackpackType !== "archived" && hasCurrentIssue
                                                                    ? "text-[#F79009]"
                                                                    : trackpackType === "tracking"
                                                                    ? "text-[#17B26A]"
                                                                    : "text-[#F04438]"
                                                            } leading-[13px] ml-[2px]`}
                                                        >
                                                            {product.actionCount}
                                                        </PrimaryText>
                                                        <div className="tp-icon">{selected_country?.icon}</div>
                                                    </div>
                                                </div>
                                                <div className="mt-[10px] flex items-start justify-between w-full">
                                                    <div className=" gap-[4px] items-center inline-flex">
                                                        {product?.imageSources
                                                            ?.flat()
                                                            .slice(0, 5)
                                                            ?.map((el: any, index: number) => (
                                                                <div
                                                                    key={index}
                                                                    className="w-[30px] h-[30px] rounded-[4px] border border-gray-300 overflow-hidden"
                                                                >
                                                                    {el ? (
                                                                        <img
                                                                            alt={el}
                                                                            className="w-full h-full object-contain"
                                                                            src={`${img_url}${el}`}
                                                                        />
                                                                    ) : (
                                                                        <Skeleton
                                                                            baseColor="#D0D5DD"
                                                                            count={1}
                                                                            width={28}
                                                                            height={28}
                                                                            // borderRadius={4}
                                                                            style={{
                                                                                top: "-3px",
                                                                                lineHeight: "initial",
                                                                                objectFit: "contain",
                                                                                borderRadius: "initial",
                                                                            }}
                                                                        />
                                                                    )}
                                                                </div>
                                                            ))}

                                                        {product?.imageSources?.length > 5 && (
                                                            <PrimaryText
                                                                weight="light"
                                                                size="xs"
                                                                className="w-[30px] h-[30px] text-[#344054]  rounded-full border border-dashed border-gray-300 flex justify-center items-center"
                                                            >
                                                                +{product.imageSources.length - 5}
                                                            </PrimaryText>
                                                        )}
                                                    </div>
                                                    <div className=" cursor-pointer w-[30px] h-[30px]  bg-gray-200 rounded-[15px] justify-center items-center flex">
                                                        {trackpackType === "tracking" && (
                                                            <div
                                                                onClick={(e: React.SyntheticEvent) => {
                                                                    dispatch(
                                                                        AccountActionsCreater.setSelectedAsinsLength(0)
                                                                    )
                                                                    e.stopPropagation()
                                                                    handleSelectedOptions(product)
                                                                    handleOpenArchive()
                                                                }}
                                                            >
                                                                <TrackpackTrashIcon />
                                                            </div>
                                                        )}{" "}
                                                        {trackpackType === "archived" && (
                                                            <div
                                                                onClick={(e: React.SyntheticEvent) => {
                                                                    e.stopPropagation()
                                                                    handleSelectedOptions(product)
                                                                    handleRestoreTrackpack(product)
                                                                }}
                                                            >
                                                                <FlipArrowIcon />
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                            </div>
                        )}
                    </div>

                    {!infoDisplay && (
                        <div className="hidden w-full pt-[10px] pb-[14px] px-[10px] bg-addTrackpack-gradient rounded-[8px] border border-[#A5F0FC] gap-2.5">
                            <div className=" flex items-start gap-x-[8px] relative">
                                <RoundedAddIcon onClick={handleOpen} />
                                <div className="mt-[4px]">
                                    <PrimaryText size="lg" weight="medium" className=" text-[#0E7090] leading-normal">
                                        Add Trackpacks
                                    </PrimaryText>
                                    <PrimaryText
                                        size="small"
                                        weight="light"
                                        className="w-[196px] text-gray-700  leading-tight"
                                    >
                                        The more you build, the better you’ll know your market
                                    </PrimaryText>
                                </div>
                                <div
                                    onClick={() => setInfoDisplay(false)}
                                    className="w-5 h-5 p-2 left-[240px] cursor-pointer top-0 absolute rounded-lg justify-center items-center inline-flex"
                                >
                                    <div className="cursor-pointer absolute right-0 top-0">
                                        <CrossIcon />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}
