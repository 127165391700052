import { ImageUnavailable } from "../../../assets/svgs/imageUnavailable.svg"
import { ReactComponent as ReportSummaryIcon } from "../../../assets/svgs/report-summary.svg"
import { PrimaryText } from "../../elements/primaryText.element"

const ReportSummary = (reportSummary: any) => {
    return (
        <>
            <div className=" mt-[20px] overflow-y-auto -mr-[30px] w-full">
                <div className="mt-[28px] bg-[#ECFDFF] border border-[#A5F0FC] rounded-[12px] p-6 ">
                    <div
                        className="p-[8px] inline-flex items-center justify-center rounded-[12px] bg-[#67E3F9]"
                        style={{
                            boxShadow: "0px 1px 2px 0px #1018280D",
                        }}
                    >
                        <ReportSummaryIcon />
                    </div>
                    <PrimaryText weight="medium" className="text-gray-700 text-[24px] my-[26px] ml-[6px]">
                        Report Summary
                    </PrimaryText>
                    <div className="border border-[#A5F0FC] p-[24px] rounded-[24px] bg-white">
                        <PrimaryText size="lg" weight="medium" className="text-[#06AED4] mb-[12px]">
                            Overview
                        </PrimaryText>
                        <PrimaryText weight="light" size="small" className="text-gray-600">
                            The main image of the target ASIN scores moderately across key metrics like clickability,
                            differentiation, and clarity of features. Qualitative feedback highlights areas for
                            improvement in showcasing product features and quality more distinctly, along with enhancing
                            production values.
                        </PrimaryText>
                    </div>
                    <div className="border border-[#A5F0FC] p-[24px] rounded-[24px] bg-white my-[24px]">
                        <PrimaryText size="lg" weight="medium" className="text-[#06AED4] mb-[12px]">
                            Key Takeaways
                        </PrimaryText>
                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3">
                            <div className="bg-[#F5FEFF] p-[16px] pb-[20px] border border-[#A5F0FC] rounded-[12px]">
                                <PrimaryText size="small" weight="medium" className="text-[#06AED4] mb-[6px]">
                                    How to boost Clickability
                                </PrimaryText>
                                <ul className="list-disc marker:text-gray-600 pl-[22px]">
                                    <li className="mb-[6px]">
                                        <PrimaryText weight="light" size="xs" className="text-gray-600">
                                            <b className="font-medium underline">Feedback:</b>&nbsp; The image color is
                                            eye-catching but lacks supplementary elements like packaging or human
                                            interaction.
                                        </PrimaryText>
                                    </li>
                                    <li className="mb-[6px]">
                                        <PrimaryText weight="light" size="xs" className="text-gray-600">
                                            <b className="font-medium underline">Recommendations:</b>&nbsp; Incorporate
                                            packaging or a human element (e.g., a hand) to make the image more relatable
                                            and inviting
                                        </PrimaryText>
                                    </li>
                                </ul>
                            </div>
                            <div className="bg-[#F5FEFF] p-[16px] pb-[20px] border border-[#A5F0FC] rounded-[12px]">
                                <PrimaryText size="small" weight="medium" className="text-[#06AED4] mb-[6px]">
                                    How to boost Differentiation
                                </PrimaryText>
                                <ul className="list-disc marker:text-gray-600 pl-[22px]">
                                    <li className="mb-[6px]">
                                        <PrimaryText weight="light" size="xs" className="text-gray-600">
                                            <b className="font-medium underline">Feedback:</b>&nbsp; The image color is
                                            eye-catching but lacks supplementary elements like packaging or human
                                            interaction.
                                        </PrimaryText>
                                    </li>
                                    <li className="mb-[6px]">
                                        <PrimaryText weight="light" size="xs" className="text-gray-600">
                                            <b className="font-medium underline">Recommendations:</b>&nbsp; Incorporate
                                            packaging or a human element (e.g., a hand) to make the image more relatable
                                            and inviting
                                        </PrimaryText>
                                    </li>
                                </ul>
                            </div>
                            <div className="bg-[#F5FEFF] p-[16px] pb-[20px] border border-[#A5F0FC] rounded-[12px]">
                                <PrimaryText size="small" weight="medium" className="text-[#06AED4] mb-[6px]">
                                    How to boost Clarity of Product Features
                                </PrimaryText>
                                <ul className="list-disc marker:text-gray-600 pl-[22px]">
                                    <li className="mb-[6px]">
                                        <PrimaryText weight="light" size="xs" className="text-gray-600">
                                            <b className="font-medium underline">Feedback:</b>&nbsp; The image color is
                                            eye-catching but lacks supplementary elements like packaging or human
                                            interaction.
                                        </PrimaryText>
                                    </li>
                                    <li className="mb-[6px]">
                                        <PrimaryText weight="light" size="xs" className="text-gray-600">
                                            <b className="font-medium underline">Recommendations:</b>&nbsp; Incorporate
                                            packaging or a human element (e.g., a hand) to make the image more relatable
                                            and inviting
                                        </PrimaryText>
                                    </li>
                                </ul>
                            </div>

                            {/* Second Row: 2 Columns */}
                            <div className="lg:col-span-3 grid grid-cols-2 gap-3">
                                <div className=" bg-[#F5FEFF] p-[16px] pb-[20px] border border-[#A5F0FC] rounded-[12px]">
                                    <PrimaryText size="small" weight="medium" className="text-[#06AED4] mb-[6px]">
                                        How to boost Clarity of Product Quality
                                    </PrimaryText>
                                    <ul className="list-disc marker:text-gray-600 pl-[22px]">
                                        <li className="mb-[6px]">
                                            <PrimaryText weight="light" size="xs" className="text-gray-600">
                                                <b className="font-medium underline">Feedback:</b>&nbsp; The image color
                                                is eye-catching but lacks supplementary elements like packaging or human
                                                interaction.
                                            </PrimaryText>
                                        </li>
                                        <li className="mb-[6px]">
                                            <PrimaryText weight="light" size="xs" className="text-gray-600">
                                                <b className="font-medium underline">Recommendations:</b>&nbsp;
                                                Incorporate packaging or a human element (e.g., a hand) to make the
                                                image more relatable and inviting
                                            </PrimaryText>
                                        </li>
                                    </ul>
                                </div>
                                <div className="bg-[#F5FEFF] p-[16px] pb-[20px] border border-[#A5F0FC] rounded-[12px]">
                                    <PrimaryText size="small" weight="medium" className="text-[#06AED4] mb-[6px]">
                                        How to boost Production Values
                                    </PrimaryText>
                                    <ul className="list-disc marker:text-gray-600 pl-[22px]">
                                        <li className="mb-[6px]">
                                            <PrimaryText weight="light" size="xs" className="text-gray-600">
                                                <b className="font-medium underline">Feedback:</b>&nbsp; The image color
                                                is eye-catching but lacks supplementary elements like packaging or human
                                                interaction.
                                            </PrimaryText>
                                        </li>
                                        <li className="mb-[6px]">
                                            <PrimaryText weight="light" size="xs" className="text-gray-600">
                                                <b className="font-medium underline">Recommendations:</b>&nbsp;
                                                Incorporate packaging or a human element (e.g., a hand) to make the
                                                image more relatable and inviting
                                            </PrimaryText>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="border border-[#A5F0FC] p-[24px] rounded-[24px] bg-white">
                        <PrimaryText size="lg" weight="medium" className="text-[#06AED4] mb-[12px]">
                            Detailed Analysis
                        </PrimaryText>
                        <PrimaryText weight="light" size="small" className="text-gray-600">
                            The target ASIN’s image lacks packaging or accessory highlights that make competitor images
                            more appealing. While the color scheme is attractive, it doesn’t stand out in
                            differentiation. Competitor 1, in particular, benefits from brighter colors, staged
                            accessories, and a more polished look, which likely contributes to better click-through
                            rates. Competitor 2 is weaker overall but includes some clever elements, like using a hand
                            for scale.
                        </PrimaryText>
                    </div>
                    <div className="border border-[#A5F0FC] p-[24px] rounded-[24px] bg-white my-[24px]">
                        <PrimaryText size="lg" weight="medium" className="text-[#06AED4] mb-[12px]">
                            Detailed Analysis
                        </PrimaryText>
                        <div className="grid grid-cols-2 gap-3">
                            <div className="bg-[#F5FEFF] p-[16px] pb-[20px] border border-[#A5F0FC] rounded-[12px] flex items-start gap-[14px]">
                                <div className="w-[144px] h-[144px] rounded-[10px] border border-[#A5F0FC] bg-white">
                                    <ImageUnavailable width={"144"} height={"144"} />
                                </div>
                                <div>
                                    <PrimaryText size="small" weight="medium" className="text-[#06AED4] mb-[12px]">
                                        Competitor #1
                                    </PrimaryText>
                                    <PrimaryText size="xs" weight="medium" className="text-gray-600 mb-[2px]">
                                        Strengths
                                    </PrimaryText>
                                    <ul className="list-decimal  mb-[12px] pl-[22px]">
                                        <li className="marker:text-gray-600 marker:text-[12px] marker:mr-[8px]">
                                            <PrimaryText weight="light" size="xs" className="text-gray-600">
                                                Bright colors and staged presentation make the image highly clickable.
                                            </PrimaryText>
                                        </li>
                                        <li className="marker:text-gray-600 marker:text-[12px] marker:mr-[8px]">
                                            <PrimaryText weight="light" size="xs" className="text-gray-600">
                                                Accessories are clearly visible and add perceived value.
                                            </PrimaryText>
                                        </li>
                                        <li className="marker:text-gray-600 marker:text-[12px] marker:mr-[8px]">
                                            <PrimaryText weight="light" size="xs" className="text-gray-600">
                                                Professional quality enhances premium perception.
                                            </PrimaryText>
                                        </li>
                                    </ul>
                                    <PrimaryText size="xs" weight="medium" className="text-gray-600 mb-[2px]">
                                        Weaknesses
                                    </PrimaryText>
                                    <ul className="list-decimal pl-[22px]">
                                        <li className="marker:text-gray-600 marker:text-[12px] marker:mr-[8px]">
                                            <PrimaryText weight="light" size="xs" className="text-gray-600">
                                                Slight overuse of color could distract from the product focus.
                                            </PrimaryText>
                                        </li>
                                        <li className="marker:text-gray-600 marker:text-[12px] marker:mr-[8px]">
                                            <PrimaryText weight="light" size="xs" className="text-gray-600">
                                                Accessories may overwhelm the primary product.{" "}
                                            </PrimaryText>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="bg-[#F5FEFF] p-[16px] pb-[20px] border border-[#A5F0FC] rounded-[12px] flex items-start gap-[14px]">
                                <div className="w-[144px] h-[144px] rounded-[10px] border border-[#A5F0FC] bg-white">
                                    <ImageUnavailable width={"144"} height={"144"} />
                                </div>
                                <div>
                                    <PrimaryText size="small" weight="medium" className="text-[#06AED4] mb-[12px]">
                                        Competitor #2
                                    </PrimaryText>
                                    <PrimaryText size="xs" weight="medium" className="text-gray-600 mb-[2px]">
                                        Strengths
                                    </PrimaryText>
                                    <ul className="list-decimal pl-[22px] mb-[12px]">
                                        <li className="marker:text-gray-600 marker:text-[12px] marker:mr-[8px]">
                                            <PrimaryText weight="light" size="xs" className="text-gray-600">
                                                Bright colors and staged presentation make the image highly clickable.
                                            </PrimaryText>
                                        </li>
                                        <li className="marker:text-gray-600 marker:text-[12px] marker:mr-[8px]">
                                            <PrimaryText weight="light" size="xs" className="text-gray-600">
                                                Accessories are clearly visible and add perceived value.
                                            </PrimaryText>
                                        </li>
                                    </ul>
                                    <PrimaryText size="xs" weight="medium" className="text-gray-600 mb-[2px]">
                                        Weaknesses
                                    </PrimaryText>
                                    <ul className="list-decimal pl-[22px]">
                                        <li className="marker:text-gray-600 marker:text-[12px] marker:mr-[8px]">
                                            <PrimaryText weight="light" size="xs" className="text-gray-600">
                                                Slight overuse of color could distract from the product focus.
                                            </PrimaryText>
                                        </li>
                                        <li className="marker:text-gray-600 marker:text-[12px] marker:mr-[8px]">
                                            <PrimaryText weight="light" size="xs" className="text-gray-600">
                                                Accessories may overwhelm the primary product.{" "}
                                            </PrimaryText>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ReportSummary
