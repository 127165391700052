import { ListingAnalyzerAction } from "../actions/listingAnalyzer.action"
import { ListinAnalyzerStateClass } from "../classes/lisitingAnalyzerState.class"

const getInitialState = (): IListingAnalyzerState => {
    const initialState = new ListinAnalyzerStateClass()

    initialState.reportPayload = null

    return initialState
}

export const ListingAnalyzerReducer = (
    state: IListingAnalyzerState = getInitialState(),
    action: ISagaAction
): Partial<IListingAnalyzerState> => {
    switch (action.type) {
        case ListingAnalyzerAction.GET_REPORT_LIST_DATA:
            return {
                ...state,
                reportListingData: action.payload,
            }

        case ListingAnalyzerAction.SET_REPORT_LIST_DATA:
            return {
                ...state,
                reportsListData: action.payload,
            }

        case ListingAnalyzerAction.SET_REPORT_DATA: {
            return {
                ...state,
                reportPayload: {
                    ...state.reportPayload,
                    ...action.payload,
                },
            }
        }
        case ListingAnalyzerAction.SET_REPORT_DATA_EMPTY: {
            return {
                ...state,
                reportPayload: undefined,
            }
        }
        case ListingAnalyzerAction.SET_REPORT_DATA_BY_ID: {
            return {
                ...state,
                reportDataByID: action.payload,
            }
        }
        // CREATE REPORT
        case ListingAnalyzerAction.CREATE_REPORT: {
            return {
                ...state,
            }
        }
        // report summary data
        case ListingAnalyzerAction.SET_REPORT_SUMMARY: {
            return {
                ...state,
                reportSummary: action.payload,
            }
        }
        // response Data
        case ListingAnalyzerAction.SET_STRUCTURED_RESPONSE_DATA: {
            return {
                ...state,
                structuredResponseData: action.payload,
            }
        }
        case ListingAnalyzerAction.SUBMIT_REPORT_QUESTION_RESPONSE: {
            return {
                ...state,
            }
        }

        default:
            return state
    }
}
