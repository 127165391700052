export const ListingAnalyzerIcon = (props: IArrowProps) => {
    return (
        <>
            <svg
                className={props.className}
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M25.6666 14C25.6666 20.4434 20.4433 25.6667 14 25.6667M25.6666 14C25.6666 7.55672 20.4433 2.33337 14 2.33337M25.6666 14H21M14 25.6667C7.55666 25.6667 2.33331 20.4434 2.33331 14M14 25.6667V21M2.33331 14C2.33331 7.55672 7.55666 2.33337 14 2.33337M2.33331 14H6.99998M14 2.33337V7.00004"
                    stroke={props.color}
                    stroke-width="1.4"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        </>
    )
}
