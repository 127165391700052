import { useEffect, useMemo, useRef, useState } from "react"
import { useForm } from "react-hook-form"
import Skeleton from "react-loading-skeleton"
import { useDispatch } from "react-redux"
import { ListingAnalyzerActionCreator } from "../../../../actions/listingAnalyzer.action"
import { PaginationLeftArrow } from "../../../../assets/svgs/paginationLeftArrow.svg"
import { PaginationRightArrow } from "../../../../assets/svgs/paginationRightArrow.svg"
import { CrossNewIcon } from "../../../../assets/svgs/xIcon.svg"
import { img_url } from "../../../../config/dashboard.config"
import { reportModalTrackpacks } from "../../../../helpers/util.helper"
import StepNavigator from "../../../common/stepsNavigator.component"
import { PrimaryText } from "../../../elements/primaryText.element"
import { AMAZON_DOMAINS } from "../../../marketing/domains.config"

const ListingReportModalStepOneView: React.FC<ReportModalFlowProps> = ({
    asinHeaderText,
    icon,
    handleCloseDialog,
    handleNext,
    buttonText,
    isPrevButton,
    handlePrev,
    totalSteps,
    currentStep,
    asinHeaderDescription,
    previousStep,
    updateStepData,
    currentStepData,
    isLoading,
    extendedTrackpacks,
}) => {
    const { control, handleSubmit, watch, setValue } = useForm()
    const dispatch = useDispatch()
    const [hasScroll, setHasScroll] = useState(false)
    const scrollableDivRef = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
        if (scrollableDivRef.current) {
            setHasScroll(scrollableDivRef.current.scrollHeight > scrollableDivRef.current.clientHeight)
        }
    }, [extendedTrackpacks])

    const [localData, setLocalData] = useState(currentStepData)

    const selectedObject = extendedTrackpacks?.trackpacks?.find(
        (item: { metadata: { id: number | string } }) => item.metadata.id == localData?.selectedProduct?.id
    )

    useEffect(() => {
        updateStepData(localData)
    }, [localData])

    const onSubmit = () => {
        if (localData?.selectedProduct?.id) {
            dispatch(ListingAnalyzerActionCreator.setReportData({ selectedTrackpack: selectedObject }))
            handleNext && handleNext()
        }
    }

    const analyzeReportTrackpacks = useMemo(() => {
        if (!extendedTrackpacks) return []

        return reportModalTrackpacks(extendedTrackpacks)
    }, [extendedTrackpacks])

    const handleCardSelect = (product: any) => {
        // dispatch(
        //     ListingAnalyzerActionCreator.setReportData({
        //         product,
        //     })
        // )
        setLocalData((prev: any) => ({ ...prev, selectedProduct: product }))
    }

    return (
        <>
            <div className="flex items-center justify-between">
                <div className=" rounded-[12px]">{icon}</div>
                <div className="cursor-pointer">
                    <CrossNewIcon onClick={handleCloseDialog} />
                </div>
            </div>
            <PrimaryText className="text-gray-700 text-[24px] font-medium leading-[32px] mt-[24px] ml-[6px]">
                {asinHeaderText}
            </PrimaryText>

            <PrimaryText size="sm-medium" weight="light" className="text-gray-700 mt-[8px] ml-[6px]">
                {asinHeaderDescription}
            </PrimaryText>

            <form onSubmit={handleSubmit(onSubmit)} className=" mt-[36px]">
                <>
                    {isLoading ? (
                        <div className="flex justify-center">
                            <div className="w-16 h-16 rounded-full animate-spin border  border-[#0E7090] border-t-transparent"></div>
                        </div>
                    ) : (
                        <div
                            ref={scrollableDivRef}
                            className={`grid grid-cols-3 gap-[12px] overflow-y-auto semi-automated`}
                            style={{
                                paddingRight: hasScroll ? "12px" : "0",
                                maxHeight: "305px",
                            }}
                        >
                            {analyzeReportTrackpacks &&
                                analyzeReportTrackpacks?.map((product: any, index: number) => {
                                    const selected_country = AMAZON_DOMAINS?.find((val) => {
                                        return val?.value === product.amazonDomain
                                    })
                                    const isSelected = localData.selectedProduct?.id === product.id

                                    return (
                                        <div
                                            key={index}
                                            onClick={() => handleCardSelect(product)}
                                            style={{
                                                outline: isSelected ? `2px solid #088AB2` : "1px solid #EAECF0",
                                                outlineOffset: isSelected ? `-2px` : `-1px`,
                                                backgroundColor: isSelected ? ` #ECFDFF` : "#F2F4F7",
                                            }}
                                            className={`border border-gray-200 last-child p-[10px] bg-gray-100 rounded-[10px] cursor-pointer -outline-offset-1
                                    `}
                                        >
                                            <div className="justify-between flex w-full">
                                                <div>
                                                    <PrimaryText
                                                        size="xs"
                                                        weight="medium"
                                                        className="text-gray-600 leading-[18px] line-clamp-1 max-w-[28ch]"
                                                    >
                                                        {product.title}
                                                    </PrimaryText>
                                                    <div className="flex gap-[5px]">
                                                        <PrimaryText
                                                            size="xs-small"
                                                            weight="medium"
                                                            className={` !text-[9px] text-[#06AED4]`}
                                                        >
                                                            ASINs:
                                                        </PrimaryText>
                                                        <PrimaryText
                                                            size="xs-small"
                                                            weight="light"
                                                            className="text-gray-700 !text-[9px] "
                                                        >
                                                            {product.userASINs} Users
                                                        </PrimaryText>
                                                        <PrimaryText className={` text-[9px] text-[#06AED4]`}>
                                                            •
                                                        </PrimaryText>
                                                        <PrimaryText
                                                            size="xs-small"
                                                            weight="light"
                                                            className="text-slate-700 !text-[9px] "
                                                        >
                                                            {product.competitorASINs} Competitors
                                                        </PrimaryText>
                                                        <PrimaryText className={` text-[9px] text-[#06AED4] `}>
                                                            •
                                                        </PrimaryText>
                                                        <PrimaryText
                                                            size="xs-small"
                                                            weight="light"
                                                            className="text-slate-700 !text-[9px]"
                                                        >
                                                            {product.archived} Archived
                                                        </PrimaryText>
                                                    </div>
                                                </div>
                                                <div
                                                    className={`w-auto h-[16px] pl-[6px] pr-[3px]  bg-white rounded-[16px] outline outline-1 outline-[#A5F0FC] inline-flex items-center gap-[4px]`}
                                                >
                                                    <PrimaryText
                                                        weight="book"
                                                        size="xs-medium"
                                                        className={` leading-[13px] ml-[2px] text-[#088AB2]`}
                                                    >
                                                        {product.actionCount}
                                                    </PrimaryText>
                                                    <div className="tp-icon">{selected_country?.icon}</div>
                                                </div>
                                            </div>
                                            <div className="mt-[10px] flex items-start justify-between w-full">
                                                <div className=" gap-[4px] items-center inline-flex">
                                                    {product?.imageSources
                                                        ?.flat()
                                                        .slice(0, 5)
                                                        ?.map((el: any, index: number) => (
                                                            <div
                                                                key={index}
                                                                className="w-[30px] h-[30px] rounded-[4px] border border-gray-300 overflow-hidden"
                                                            >
                                                                {el ? (
                                                                    <img
                                                                        alt={el}
                                                                        className="w-full h-full object-contain"
                                                                        src={`${img_url}${el}`}
                                                                    />
                                                                ) : (
                                                                    <Skeleton
                                                                        baseColor="#D0D5DD"
                                                                        count={1}
                                                                        width={28}
                                                                        height={28}
                                                                        style={{
                                                                            top: "-3px",
                                                                            lineHeight: "initial",
                                                                            objectFit: "contain",
                                                                            borderRadius: "initial",
                                                                        }}
                                                                    />
                                                                )}
                                                            </div>
                                                        ))}

                                                    {product?.imageSources?.length > 5 && (
                                                        <PrimaryText
                                                            weight="light"
                                                            size="xs"
                                                            className="w-[30px] h-[30px] text-[#344054]  rounded-full border border-dashed border-gray-300 flex justify-center items-center"
                                                        >
                                                            +{product.imageSources.length - 5}
                                                        </PrimaryText>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                        </div>
                    )}

                    <div className="flex justify-center gap-4  items-center mt-[36px]">
                        <div className="flex items-center">
                            <PrimaryText
                                onClick={handlePrev}
                                className={" bg-gray-100 rounded-[6px] p-[5px] cursor-pointer "}
                            >
                                <PaginationLeftArrow />
                            </PrimaryText>
                        </div>

                        <button type="submit" className="flex items-center">
                            <PrimaryText
                                className={` ${
                                    localData?.selectedProduct?.id ? "bg-[#A5F0FC]" : "bg-gray-100"
                                }  rounded-[6px] p-[5px] cursor-pointer`}
                            >
                                <PaginationRightArrow stroke={localData?.selectedProduct?.id ? "#0E7090" : "#D0D5DD"} />
                            </PrimaryText>
                        </button>
                    </div>
                </>
            </form>
            <div className="mt-[36px]">
                <StepNavigator
                    currentStep={currentStep}
                    previousStep={previousStep}
                    totalSteps={totalSteps}
                    strokeColor="#667085"
                />
            </div>
        </>
    )
}

export default ListingReportModalStepOneView
