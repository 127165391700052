import { Outlet, useLocation, useNavigate, useNavigation } from "react-router-dom"

import { useCallback, useEffect } from "react"
import toast from "react-hot-toast"
import { useDispatch, useSelector } from "react-redux"
import { AuthActionCreator } from "../actions/auth.action"
import { NotificationsActionsCreator } from "../actions/notifications.action"
import { ProductsActionCreator } from "../actions/products.action"
import { DashboardHeader } from "../components/dashboard/dashboardHeader.component"
import AddTrackPackMain from "../components/dashboard/modals/addTrackPackModalSteps/addTrackPackMainModal"
import AdditionalTrackPackMain from "../components/dashboard/modals/additionalTrackPackModals/additionalTrackPackMain.component"
import ArchiveMainModal from "../components/dashboard/modals/archiveModals/archiveMainModal.component"
import FlowSelectionMain from "../components/dashboard/modals/flowSelectionModal/flowSelectionMain.component"
import ListingAnalyzerModalMain from "../components/dashboard/modals/lisitingAnalyzerModals/lisitingAnalyzerFlowModal.component"
import PerformanceTrackerQuickTourModalComponent from "../components/dashboard/modals/quickTourModals/performanceTrackerQuickTour/index.component"
import TrackpackHubQuickTourModalComponent from "../components/dashboard/modals/quickTourModals/trackpackHubQuickTour/index.component"
import SemiAutomatedAddTrackPackMain from "../components/dashboard/modals/semiAutomatedAddTrackpackModals/semiAutomatedFlow.component"
import NotificationAlerComponent from "../components/dashboard/notfitication-alerts/index.component"
import { SideNavigation } from "../components/dashboard/sideNavigation.component"
import { ROUTES_CONFIG } from "../config/routes.config"
import { checkArchivedStatusAndNavigate } from "../helpers/util.helper"
import { AccountStateSelector } from "../selectors/accountState.selector"
import { AuthStateSelector } from "../selectors/authState.selector"
import { ProductStateSelector } from "../selectors/product.selector"

let apiCall: any = null
export const DashboardContainer = () => {
    let navigation = useNavigation()
    const navigate = useNavigate()
    const location = useLocation()
    const { userActionData } = useSelector(AccountStateSelector)
    const { selectedDropdownList } = useSelector(ProductStateSelector)
    const { getAccessLevel } = useSelector(AuthStateSelector)
    const { pathname, search } = location
    const params = new URLSearchParams(search)
    const asins = params.get("asins")
    const id = params.get("id")

    const debounce = (func: Function, delay: number) => {
        let timeoutId: ReturnType<typeof setTimeout>
        return (...args: any[]) => {
            if (timeoutId) {
                clearTimeout(timeoutId)
            }
            timeoutId = setTimeout(() => {
                func(...args)
            }, delay)
        }
    }

    const notifyError = (text: string) => toast.error(text)
    const dispatch = useDispatch()

    useEffect(() => {
        const TEN_MINUTES = 10 * 60 * 1000
        const lastMeCall = localStorage.getItem("lastMeCallTimestamp")
        const now = Date.now()

        if (!lastMeCall || now - parseInt(lastMeCall, 10) > TEN_MINUTES) {
            dispatch(
                AuthActionCreator.getAccessLevel(
                    () => {
                        localStorage.setItem("lastMeCallTimestamp", now.toString())
                    },
                    (res: { message: string }) => {
                        notifyError(res.message)
                    }
                )
            )
        }
    }, [])

    useEffect(() => {
        if (userActionData !== undefined) {
            dispatch(
                NotificationsActionsCreator.getAllNotifications(
                    {},
                    { as_user: userActionData && userActionData?.id + "" }
                )
            )
        } else dispatch(NotificationsActionsCreator.getAllNotifications())
    }, [userActionData])

    const debouncedGettrackpackList = useCallback(
        debounce(() => {
            gettrackpackList()
            if (id) {
                dispatch(ProductsActionCreator.getExtendedTrackingData(String(id), {}, { only_active_trackings: true }))
            } else {
                getFirstTrackpackData()
            }
        }, 100),
        [userActionData]
    )
    useEffect(() => {
        // debouncedGettrackpackList()
        gettrackpackList()
        if (id) {
            dispatch(ProductsActionCreator.getExtendedTrackingData(String(id), {}, { only_active_trackings: true }))
        } else {
            getFirstTrackpackData()
        }
    }, [])

    useEffect(() => {
        const asinsArray = asins?.split(",")

        const isArchived = checkArchivedStatusAndNavigate(
            selectedDropdownList && selectedDropdownList?.trackpacks,
            String(id),
            asinsArray!
        )
        if (isArchived) {
            return navigate(ROUTES_CONFIG.trackpackCentral)
        } else {
            if (id) {
                if (asins) {
                    dispatch(
                        NotificationsActionsCreator.notifiSelection({
                            notifiAsin: asinsArray,
                            selectedTrackpackID: id,
                        })
                    )
                    dispatch(
                        ProductsActionCreator.setSelectedTrackPackValues({
                            selectedTrackpackID: String(id),
                            defualtSelectedAsin: asinsArray,
                        })
                    )
                }

                if (pathname) {
                    navigate(ROUTES_CONFIG.dashboard)
                }
            }
        }
    }, [location, dispatch, navigate, selectedDropdownList])

    const gettrackpackList = () => {
        if (userActionData !== undefined) {
            dispatch(
                ProductsActionCreator.getTrackpackList(
                    {},
                    { as_user: userActionData && userActionData?.id + "", status: "active" }
                )
            )
        } else {
            dispatch(ProductsActionCreator.getTrackpackList({}, { status: "active" }))
        }
    }
    const getFirstTrackpackData = () => {
        if (userActionData !== undefined) {
            dispatch(
                ProductsActionCreator.getFirstTrackpackData({}, { as_user: userActionData && userActionData?.id + "" })
            )
        } else {
            dispatch(ProductsActionCreator.getFirstTrackpackData())
        }
    }

    return (
        <div className="bg-userAccount-custom-gradient pt-[32px] pr-[28px] pb-[24px] pl-[24px] ">
            <DashboardHeader />
            <div className="flex flex-row items-start gap-[20px] w-full " style={{ height: "calc(100vh - 165px)" }}>
                <SideNavigation />
                {navigation.state !== "idle" ? <div className="is-loading ">Loading...</div> : null}

                {/* <div className="bg-white rounded-tl-2xl rounded-bl-2xl flex-1 h-full"> */}
                <div className="h-full" style={{ width: "calc(100% - 80px)" }}>
                    <Outlet />
                </div>
            </div>
            <AddTrackPackMain />
            <AdditionalTrackPackMain />
            <ArchiveMainModal />
            <PerformanceTrackerQuickTourModalComponent />
            <TrackpackHubQuickTourModalComponent />
            <NotificationAlerComponent />
            <FlowSelectionMain />
            <SemiAutomatedAddTrackPackMain />
            <ListingAnalyzerModalMain />
        </div>
    )
}
